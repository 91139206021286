import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({ match }) => {



  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} />
        <Route path={`${match.url}profilesettings`} component={asyncComponent(() => import('./UserProfileSettings'))} />
        {/* <Route path={`${match.url}listareas`} component={asyncComponent(() => import('./ManageArea/ListAreas'))}/> */}
        <Route path={`${match.url}listareas`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageArea/ListAreas'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addarea`} component={asyncComponent(() => import('./ManageArea/AddArea'))}/> */}
        <Route path={`${match.url}addarea`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageArea/AddArea'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editarea/:id`} component={asyncComponent(() => import('./ManageArea/EditArea'))}/> */}
        <Route path={`${match.url}editarea/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageArea/EditArea'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}listfrontlines`} component={asyncComponent(() => import('./ManageFrontline/ListFrontlines'))}/> */}
        <Route path={`${match.url}listfrontlines`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageFrontline/ListFrontlines'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addfrontline`} component={asyncComponent(() => import('./ManageFrontline/AddFrontline'))}/> */}
        <Route path={`${match.url}addfrontline`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageFrontline/AddFrontline'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editfrontline/:id`} component={asyncComponent(() => import('./ManageFrontline/EditFrontline'))}/>      */}
        <Route path={`${match.url}editfrontline/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageFrontline/EditFrontline'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}listregions`} component={asyncComponent(() => import('./ManageRegion/ListRegions'))}/> */}
        <Route path={`${match.url}listregions`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageRegion/ListRegions'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addregion`} component={asyncComponent(() => import('./ManageRegion/AddRegion'))}/> */}
        <Route path={`${match.url}addregion`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageRegion/AddRegion'); } else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editregion/:id`} component={asyncComponent(() => import('./ManageRegion/EditRegion'))}/> */}
        <Route path={`${match.url}editregion/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageRegion/EditRegion'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}listbranches`} component={asyncComponent(() => import('./ManageBranches/ListBranches'))}/> */}
        <Route path={`${match.url}listbranches`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageBranches/ListBranches'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addbranch`} component={asyncComponent(() => import('./ManageBranches/AddBranch'))}/> */}
        <Route path={`${match.url}addbranch`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageBranches/AddBranch'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editbranch/:id`} component={asyncComponent(() => import('./ManageBranches/EditBranch'))}/> */}
        <Route path={`${match.url}editbranch/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ManageBranches/EditBranch'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}listusers`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Users/ListUsers'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}adduser`} component={asyncComponent(() => import('./Users/AddUser'))}/>       */}
        <Route path={`${match.url}adduser`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Users/AddUser'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}edituser/:id`} component={asyncComponent(() => import('./Users/EditUser'))}/> */}
        <Route path={`${match.url}edituser/:id`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Users/EditUser'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}saporderupload`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SAPOrderUpload'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}crmdataupload`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./CRMDataUpload'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Qlick sense Upload */}
        <Route path={`${match.url}qlicksenseupload`} component={asyncComponent(() => import('./QlickSenseUpload'))} />

        {/* upto done */}
        <Route path={`${match.url}report`} component={asyncComponent(() => import('./Report'))} />
        <Route path={`${match.url}changecommissionreport`} component={asyncComponent(() => {
          if (["1", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Report/ChangeCommissionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}changecommissionreport`} component={asyncComponent(() => import('./Report/ChangeCommissionReport'))}/> */}
        <Route path={`${match.url}correctionreport`} component={asyncComponent(() => {
          if (["1", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Report/CorrectionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}correctionreport`} component={asyncComponent(() => import('./Report/CorrectionReport'))}/> */}
        <Route path={`${match.url}targetdistributorreport`} component={asyncComponent(() => {
          if (["1", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Report/TargetDistributorReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}targetdistributorreport`} component={asyncComponent(() => import('./Report/TargetDistributorReport'))}/> */}
        <Route path={`${match.url}payoutstatusreport`} component={asyncComponent(() => {
          if (["1", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Report/PayoutStatusReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}payoutstatusreport`} component={asyncComponent(() => import('./Report/PayoutStatusReport'))}/> */}
        <Route path={`${match.url}payouts`} component={asyncComponent(() => import('./Payouts'))} />
        {/* <Route path={`${match.url}actualsales`} component={asyncComponent(() => import('./Sales/ActualSales'))}/> */}
        <Route path={`${match.url}actualsales`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Sales/ActualSales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}duplicatesales`} component={asyncComponent(() => import('./Sales/DuplicateList'))}/> */}
        <Route path={`${match.url}duplicatesales`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Sales/DuplicateList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}incompletesales`} component={asyncComponent(() => import('./Sales/IncompleteList'))}/>       */}
        <Route path={`${match.url}incompletesales`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Sales/IncompleteList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Qlick sense list */}
        <Route path={`${match.url}qlicksensesales`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./QlickSenseSales/QlickSenseList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Qlick sense edit from ready to process */}
        <Route path={`${match.url}edit-qlicksense-ready-to-process/:id`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./QlickSenseSales/QlickSenseList/Edit'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Qlick sense edit from ready to process */}
        <Route path={`${match.url}edit-qlicksense-exceptions/:id`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./QlickSenseSales/Exception/Edit'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Qlick sense Duplicate Equipment Sales list */}
        <Route path={`${match.url}qlicksenseduplicatesales`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./QlickSenseSales/QlickSenseDuplicateList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Qlick sense sales exception */}
        <Route path={`${match.url}qlicksenseexception`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./QlickSenseSales/Exception'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* <Route path={`${match.url}exception`}  component={asyncComponent(() => import('./Sales/Exception'))}/>       */}
        <Route path={`${match.url}exception`} component={asyncComponent(() => {
          if (["1", "3", "8", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Sales/Exception'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}nsskicker`} component={asyncComponent(() => import('./NSSKicker'))}/>       */}
        <Route path={`${match.url}nsskicker`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./NSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addnsskicker`} component={asyncComponent(() => import('./NSSKicker/AddNSSKicker'))}/>       */}
        <Route path={`${match.url}addnsskicker`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./NSSKicker/AddNSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editnsskicker/:id`} component={asyncComponent(() => import('./NSSKicker/EditNSSKicker'))}/>       */}
        <Route path={`${match.url}editnsskicker/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./NSSKicker/EditNSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}listbusinessline`} component={asyncComponent(() => import('./BusinessLine/ListBusinessLine'))}/>            */}
        <Route path={`${match.url}listbusinessline`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./BusinessLine/ListBusinessLine'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addbusinessline`} component={asyncComponent(() => import('./BusinessLine/AddBusinessLine'))}/>  */}
        <Route path={`${match.url}addbusinessline`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./BusinessLine/AddBusinessLine'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editbusinessline/:id`} component={asyncComponent(() => import('./BusinessLine/EditBusinessLine'))}/>           */}
        <Route path={`${match.url}editbusinessline/:id`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./BusinessLine/EditBusinessLine'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}listprojecttype`} component={asyncComponent(() => import('./ProjectTypes/ProjectType/ListProjectType'))}/>           */}
        <Route path={`${match.url}listprojecttype`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ProjectTypes/ProjectType/ListProjectType'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addprojecttype`} component={asyncComponent(() => import('./ProjectTypes/ProjectType/AddProjectType'))}/> */}
        <Route path={`${match.url}addprojecttype`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ProjectTypes/ProjectType/AddProjectType'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editprojecttype/:id`} component={asyncComponent(() => import('./ProjectTypes/ProjectType/EditProjectType'))}/>           */}
        <Route path={`${match.url}editprojecttype/:id`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ProjectTypes/ProjectType/EditProjectType'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}listsubprojecttype`} component={asyncComponent(() => import('./ProjectTypes/SubProjectType/ListSubProjectType'))}/>           */}
        <Route path={`${match.url}listsubprojecttype`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ProjectTypes/SubProjectType/ListSubProjectType'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addsubprojecttype`} component={asyncComponent(() => import('./ProjectTypes/SubProjectType/AddSubProjectType'))}/>           */}
        <Route path={`${match.url}addsubprojecttype`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ProjectTypes/SubProjectType/AddSubProjectType'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editsubprojecttype/:id`} component={asyncComponent(() => import('./ProjectTypes/SubProjectType/EditSubProjectType'))}/>           */}
        <Route path={`${match.url}editsubprojecttype/:id`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./ProjectTypes/SubProjectType/EditSubProjectType'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* up*/}
        {/* <Route path={`${match.url}listpayout`} component={asyncComponent(() => import('./PayoutSettings/ListPayout'))}/>         */}
        <Route path={`${match.url}listpayout`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./PayoutSettings/ListPayout'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addpayout`} component={asyncComponent(() => import('./PayoutSettings/AddPayout'))}/> */}
        <Route path={`${match.url}addpayout`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./PayoutSettings/AddPayout'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editpayout/:id`} component={asyncComponent(() => import('./PayoutSettings/EditPayout'))}/>         */}
        <Route path={`${match.url}editpayout/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./PayoutSettings/EditPayout'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}listincentives`} component={asyncComponent(() => import('./IncentivesSettings/ListIncentives'))}/>         */}
        <Route path={`${match.url}listincentives`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./IncentivesSettings/ListIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}listincentivesbucket`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./IncentiveBucketSettings/ListIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}listbravoincentives`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./BravoIncentiveSettings/ListIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* <Route path={`${match.url}addincentives`} component={asyncComponent(() => import('./IncentivesSettings/AddIncentives'))}/>         */}
        <Route path={`${match.url}addincentives`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./IncentivesSettings/AddIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}addincentivesbucket`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./IncentiveBucketSettings/AddIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addincentives`} component={asyncComponent(() => import('./IncentivesSettings/AddIncentives'))}/>         */}
        <Route path={`${match.url}addbravoincentives`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./BravoIncentiveSettings/AddIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editincentives/:id`} component={asyncComponent(() => import('./IncentivesSettings/EditIncentives'))}/>         */}
        <Route path={`${match.url}editincentives/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./IncentivesSettings/EditIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}editincentivesbucket/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./IncentiveBucketSettings/EditIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editvbincentives/:id`} component={asyncComponent(() => import('./IncentivesSettings/EditIncentives'))}/>         */}
        <Route path={`${match.url}editvbincentives/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./IncentivesSettings/VB/EditIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}editbravoincentives/:id`} component={asyncComponent(() => {
          if (["1", "3", "7", "4", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./BravoIncentiveSettings/EditIncentives'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/*  */}
        {/* <Route path={`${match.url}listcurrencies`} component={asyncComponent(() => import('./Currencies/ListCurrencies'))}/>         */}
        <Route path={`${match.url}listcurrencies`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Currencies/ListCurrencies'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}addcurrency`} component={asyncComponent(() => import('./Currencies/AddCurrency'))}/>         */}
        <Route path={`${match.url}addcurrency`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Currencies/AddCurrency'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}editcurrency/:id`} component={asyncComponent(() => import('./Currencies/EditCurrency'))}/>  */}
        <Route path={`${match.url}editcurrency/:id`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Currencies/EditCurrency'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Analytics Routes */}
        <Route path={`${match.url}dataanalytics`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./DataAnalytics/MenuOptionsPage'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}frontlineperformance`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./DataAnalytics/FrontLinePerformance'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}salespersonsperformance`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./DataAnalytics/SalesPersonsPerformance'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}salespersonflcombined`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./DataAnalytics/SalesPersonFLCombined'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}analytics/settings`} component={asyncComponent(() => {
          if (["1", "3", "13"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./DataAnalytics/Settings'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}unlockrequests`} component={asyncComponent(() => import('./Targets/UnlockRequests'))} />
        <Route path={`${match.url}globallockunlock`} component={asyncComponent(() => import('./Targets/GlobalLockUnlock'))} />
        <Route path={`${match.url}sendnotification`} component={asyncComponent(() => import('./SendNotification'))} />
        <Route path={`${match.url}auditlog`} component={asyncComponent(() => import('./AuditLog'))} />
        {/* VA Routes Admin */}
        <Route path={`${match.url}vasettings`} component={asyncComponent(() => import('./VA/VASettings/ListVASettings'))} />
        <Route path={`${match.url}va/tr120settings`} component={asyncComponent(() => import('./VA/Tr120Settings/ListTr120'))} />
        <Route path={`${match.url}editvasetting/:id`} component={asyncComponent(() => import('./VA/VASettings/EditVASettings'))} />
        <Route path={`${match.url}listvasales`} component={asyncComponent(() => import('./VA/Approvals/VASales'))} />
        {/* <Route path={`${match.url}listkpis`} component={asyncComponent(() => import('./VA/ManageKPI/DefineKPI/ListKPI'))}/> */}
        {/* <Route path={`${match.url}addkpi`} component={asyncComponent(() => import('./VA/ManageKPI/DefineKPI/AddKPI'))}/> */}
        {/* <Route path={`${match.url}editkpi/:id`} component={asyncComponent(() => import('./VA/ManageKPI/DefineKPI/EditKPI'))}/> */}
        {/* <Route path={`${match.url}listkpitoroles`} component={asyncComponent(() => import('./VA/ManageKPI/MapKPIToRole/ListKPIToRole'))}/> */}
        {/* <Route path={`${match.url}addkpitorole`} component={asyncComponent(() => import('./VA/ManageKPI/MapKPIToRole/AddKPIToRole'))}/> */}
        {/* <Route path={`${match.url}editkpitorole/:id`} component={asyncComponent(() => import('./VA/ManageKPI/MapKPIToRole/EditKPIToRole'))}/> */}
        <Route path={`${match.url}vaincentivesettings`} component={asyncComponent(() => import('./VA/VASettings/ListIncentives'))} />
        {/* <Route path={`${match.url}bravoincentivesettings`} component={asyncComponent(() => import('./VA/VASettings/ListIncentives'))} /> */}
        <Route path={`${match.url}keyaccountsupload`} component={asyncComponent(() => import('./VA/KeyAccountsUpload'))} />
        {/* <Route path={`${match.url}targetupload`} component={asyncComponent(() => import('./VA/TargetUpload'))} /> */}


        <Route path={`${match.url}vaaddincentives`} component={asyncComponent(() => import('./VA/VASettings/AddIncentives'))} />
        <Route path={`${match.url}vapayoutsettings`} component={asyncComponent(() => import('./VA/VASettings/PayoutSettings/ListPayoutsettings'))} />
        <Route path={`${match.url}vaaddpayoutsettings`} component={asyncComponent(() => import('./VA/VASettings/PayoutSettings/AddPayoutsettings'))} />
        <Route path={`${match.url}vaeditpayoutsettings/:id`} component={asyncComponent(() => import('./VA/VASettings/PayoutSettings/EditPayoutsettings'))} />
        <Route path={`${match.url}contractupload`} component={asyncComponent(() => import('./VA/ContractUpload'))} />
        <Route path={`${match.url}forecastupload`} component={asyncComponent(() => import('./VA/ForecastUpload'))} />
        <Route path={`${match.url}limbo-arageing`} component={asyncComponent(() => import('./VA/LimboUploads/ArAgeing'))} />
        <Route path={`${match.url}limbo-vprp`} component={asyncComponent(() => import('./VA/LimboUploads/VPRP'))} />
        <Route path={`${match.url}limbo-zi11`} component={asyncComponent(() => import('./VA/LimboUploads/ZI11'))} />
        <Route path={`${match.url}limbo-zvc`} component={asyncComponent(() => import('./VA/LimboUploads/ZVC'))} />            

        {/* financialupload */}
        <Route path={`${match.url}financialupload`} component={asyncComponent(() => import('./VA/FinancialDataUpload'))} />
        <Route path={`${match.url}financiallist`} component={asyncComponent(() => import('./VA/FinancialDataUpload/FinancialDataList/ApprovedFinancialList'))} />
        <Route path={`${match.url}financialuploadhistory`} component={asyncComponent(() => import('./VA/FinancialDataUpload/UploadHistory'))} />


        {/* <Route path={`${match.url}vaeditincentives/:id`} component={asyncComponent(() => import('./VA/VASettings/EditIncentives/index'))}/>          */}
        <Route path={`${match.url}vaeditincentives/:id`} component={asyncComponent(() => import('./VA/VASettings/EditIncentives'))} />


        {/* <Route path={`${match.url}targetupload`} component={asyncComponent(() => import('./VA/TargetSettings/TemplateUpload/index'))}/> */}
        {/* <Route path={`${match.url}templatedownload`} component={asyncComponent(() => import('./VA/TargetSettings/TemplateDownload/index'))}/>
      <Route path={`${match.url}targetupload`} component={asyncComponent(() => import('./VA/TargetSettings/TemplateUpload/index'))}/> */}
        {/* report va */}
        <Route path={`${match.url}va/commissiondetailedreport`} component={asyncComponent(() => import('./VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/commissionsummaryreport`} component={asyncComponent(() => import('./VA/Report/CommissionSummaryReport'))} />
        <Route path={`${match.url}va/targetdistributorreport`} component={asyncComponent(() => import('./VA/Report/TargetDistributorReport'))} />
        <Route path={`${match.url}va/payoutstatusreport`} component={asyncComponent(() => import('./VA/Report/PayoutStatusReport'))} />
        <Route path={`${match.url}va/payoutreport`} component={asyncComponent(() => import('./VA/Report/PayoutReport'))} />
        <Route path={`${match.url}va/unlockrequests`} component={asyncComponent(() => import('./VA/Targets/UnlockRequests'))} />
        <Route path={`${match.url}va/globallockunlock`} component={asyncComponent(() => import('./VA/Targets/GlobalLockUnlock'))} />
        <Route path={`${match.url}va/performance/unlockrequests`} component={asyncComponent(() => import('./VA/Performance/UnlockRequests'))} />
        <Route path={`${match.url}va/viewperformance/:id/:year`} component={asyncComponent(() => import('./VA/Performance/UnlockRequests/UnlockRequestsDrawerView'))} />
        
         {/* Tickets */}
         <Route path={`${match.url}admin-tickets`} component={asyncComponent(() => import('./Tickets/index'))} />
         <Route path={`${match.url}admin-tickets-viewticket/:id`} component={asyncComponent(() => import('./Tickets/ViewTicket'))} />

          {/* Tickets for users */}
        <Route path={`${match.url}tickets`} component={asyncComponent(() => import('./TicketUsers/index'))} />
        <Route path={`${match.url}createticket`} component={asyncComponent(() => import('./TicketUsers/CreateTicket'))} />
        <Route path={`${match.url}tickets-viewticket/:id`} component={asyncComponent(() => import('./TicketUsers/ViewTicket'))} />
        
      </Switch>
      <Switch>
        {/* <Route path={`${match.url}salesmanlanding`} component={asyncComponent(() => import('./Salesman/SalesmanLanding'))}/> */}
        <Route path={`${match.url}salesmanlanding`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/SalesmanLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmandashboard`} component={asyncComponent(() => import('./Salesman/Dashboard'))}/> */}
        <Route path={`${match.url}salesmandashboard`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesman-mysales/:businessline/:projecttype`} component={asyncComponent(() => import('./Salesman/MySales/MySalesFiltered'))}/> */}
        <Route path={`${match.url}salesman-mysales/:businessline/:projecttype`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/MySales/MySalesFiltered'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        

        {/* <Route path={`${match.url}salesman-mysales`} component={asyncComponent(() => import('./Salesman/MySales'))}/> */}
        <Route path={`${match.url}salesman-mysales`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/MySales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesman-editnsskicker/:id`} component={asyncComponent(() => import('./Salesman/MySales/EditNSSKicker'))}/> */}
        <Route path={`${match.url}salesman-editnsskicker/:id`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/MySales/EditNSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesman-viewkickerdetails/:id`} component={asyncComponent(() => import('./Salesman/MySales/EditNSSKicker'))}/> */}
        <Route path={`${match.url}salesman-viewkickerdetails/:id`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/MySales/EditNSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesman-listnsskickerpendingapprovals`} component={asyncComponent(() => import('./Salesman/PendingApprovals/NSSKicker'))}/> */}
        <Route path={`${match.url}salesman-listnsskickerpendingapprovals`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/PendingApprovals/NSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesman-listnsskickerhistory`} component={asyncComponent(() => import('./Salesman/PendingApprovals/NSSKickerHistory'))}/> */}
        <Route path={`${match.url}salesman-listnsskickerhistory`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/PendingApprovals/NSSKickerHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesman-viewkickerhistorydetails/:id`} component={asyncComponent(() => import('./Salesman/PendingApprovals/NSSKickerHistory/NSSKickerHistoryDetails'))}/> */}
        <Route path={`${match.url}salesman-viewkickerhistorydetails/:id`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/PendingApprovals/NSSKickerHistory/NSSKickerHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesman-listnsskickerrejected`} component={asyncComponent(() => import('./Salesman/Rejections/NSSKicker'))}/> */}
        <Route path={`${match.url}salesman-listnsskickerrejected`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/Rejections/NSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesman-incentivesimulator`} component={asyncComponent(() => import('./Salesman/IncentiveSimulator'))}/> */}
        <Route path={`${match.url}salesman-incentivesimulator`} component={asyncComponent(() => {
          if (["12"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Salesman/IncentiveSimulator'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        
        {/* VA Paths */}
        <Route path={`${match.url}va/salesmandashboard`} component={asyncComponent(() => import('./Salesman/VA/Dashboard'))} />
        <Route path={`${match.url}vasalesperson/salesman/:id/:businessline/:businessline_name/:year/:geographicallevel`} component={asyncComponent(() => import('./VASalesPerformance/SalesManDetails'))} />
        <Route path={`${match.url}va/salesman-incentivesimulator`} component={asyncComponent(() => import('./Salesman/VA/IncentiveSimulatorSMANVA'))} />
        <Route path={`${match.url}va/salesman-mycontracts`} component={asyncComponent(() => import('./Salesman/VA/MyContracts'))} />
        <Route path={`${match.url}va/salesman-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./Salesman/VA/MyContracts'))} />
        <Route path={`${match.url}va/salesman-usersummary`} component={asyncComponent(() => import('./Salesman/VA/UserSummary/IndividualSummary')) }/>
        
      </Switch>
      <Switch>
        <Route path={`${match.url}salesperformance/sdm/:rolebasedapipath/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./SalesPerformance/SDMSalesPerformance'))} />
        <Route path={`${match.url}salesperformance/salesman/:id/:businessline/:businessline_name/:geographicallevel`} component={asyncComponent(() => import('./SalesPerformance/SalesManDetails'))} />

        <Route path={`${match.url}salesperformance/salesmanager/:id/:businessline/:businessline_name/:geographicallevel`} component={asyncComponent(() => import('./SalesPerformance/SalesManagerDetails'))} />
        <Route path={`${match.url}salesperformance/regionalmanager/:id/:businessline/:businessline_name/:geographicallevel`} component={asyncComponent(() => import('./SalesPerformance/RegionalManagerDetails'))} />
        <Route path={`${match.url}salesperformance/:rolebasedapipath/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./SalesPerformance/EmployeeDetails'))} />
        {/* <Route path={`${match.url}vaSalesperformances/:rolebasedapipath/:id/:businessline/:businessline_name/:geographicallevel`} component={asyncComponent(() => import('./VASalesPerformance/EmployeeDetailsMD/index'))}/>  */}
        <Route path={`${match.url}vaSalesperformance/salesman/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/SalesManDetails'))} />
        <Route path={`${match.url}vaSalesperformance/salesspecialist/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/SpecialistDetails'))} />


        <Route path={`${match.url}vaSalesperformance/keyaccountmanager/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/KAMDetails'))} />
        <Route path={`${match.url}vaSalesperformance/hunter/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/HunterDetails'))} />
        <Route path={`${match.url}vaSalesperformance/farmer/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/HunterDetails'))} />
        {/* <Route path={`${match.url}vaSalesperformance/keyaccountmanager/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/SalesManDetails'))} /> */}

        <Route path={`${match.url}vaSalesperformance/turkeyhunter/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/HunterDetails'))} />
        <Route path={`${match.url}vaSalesperformance/regionalmanager/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/EmployeeDetailsRM/index'))} />
        <Route path={`${match.url}vaSalesperformance/salesmanager/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/EmployeeDetailsSM/index'))} />
        {/* <Route path={`${match.url}vaSalesperformance/areabusinesslinemanager/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/EmployeeDetails/index'))}/> */}
        <Route path={`${match.url}vaSalesperformance/md/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/EmployeeDetailsMD/index'))} />
        <Route path={`${match.url}vaSalesperformance/:rolebasedapipath/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/EmployeeDetails/index'))} />


        {/* <Route path={`${match.url}vaSalesperformance/:rolebasedapipath/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => {
          if (`${match.url}vaSalesperformance/:rolebasedapipath/:id/:businessline/:businessline_name/:geographicallevel/:year` !== `${match.url}vaSalesperformance/keyaccountmanager/251/3/VA/1-5-27/2024`) {
            
            console.log('URL TEST', `${match.url}vaSalesperformance/:rolebasedapipath/:id/:businessline/:businessline_name/:geographicallevel/:year`, `${match.url}vaSalesperformance/keyaccountmanager/251/3/VA/1-5-27/2024`);
            return import('./VASalesPerformance/EmployeeDetails/index') 
            }
          })} /> */}

        <Route path={`${match.url}va/salesman-awaitinglimboapprovals/:id`} component={asyncComponent(() => import('./Salesman/VA/LimboSMVA/AwaitingLimbo/LimboAwaitingDetails'))} />

       <Route path={`${match.url}va/salesman-limbo`} component={asyncComponent(() => import('./Salesman/VA/LimboSMVA'))} />
       <Route path={`${match.url}va/salesman-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./Salesman/VA/LimboSMVA/LimboDetails'))} />
       <Route path={`${match.url}va/salesman-approvedlimboapprovals/:id`} component={asyncComponent(() => import('./Salesman/VA/LimboSMVA/ApprovedLimbo/LimboApprovalDetails'))} />
       <Route path={`${match.url}va/salesman-approvedlimboapprovals`} component={asyncComponent(() => import('./Salesman/VA/LimboSMVA/ApprovedLimbo'))} />
       <Route path={`${match.url}va/salesman-awaitinglimboapprovals`} component={asyncComponent(() => import('./Salesman/VA/LimboSMVA/AwaitingLimbo'))} />
       
       
      </Switch>
      <Switch>
        {/* <Route path={`${match.url}salesmanagerlanding`} component={asyncComponent(() => import('./SalesManager/SalesManagerLanding'))}/> */}
        <Route path={`${match.url}salesmanagerlanding`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/SalesManagerLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanagerdashboard`} component={asyncComponent(() => import('./SalesManager/Dashboard'))}/> */}
        <Route path={`${match.url}salesmanagerdashboard`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-mysales/:businessline/:projecttype`} component={asyncComponent(() => import('./SalesManager/MySales/MySalesFilteredSM'))}/> */}
        <Route path={`${match.url}salesmanager-mysales/:businessline/:projecttype`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/MySales/MySalesFilteredSM'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-mysales`} component={asyncComponent(() => import('./SalesManager/MySales'))}/> */}
        <Route path={`${match.url}salesmanager-mysales`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/MySales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-datacorrection/:id`} component={asyncComponent(() => import('./SalesManager/MySales/DataCorrectionSM'))}/> */}
        <Route path={`${match.url}salesmanager-datacorrection/:id`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/MySales/DataCorrectionSM'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-editcommission/:id`} component={asyncComponent(() => import('./SalesManager/MySales/EditCommission'))}/> */}
        <Route path={`${match.url}salesmanager-editcommission/:id`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/MySales/EditCommission'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-listtarget`} component={asyncComponent(() => import('./SalesManager/TargetSettings/ListTarget'))}/> */}
        <Route path={`${match.url}salesmanager-listtarget`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/TargetSettings/ListTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-addtarget`} component={asyncComponent(() => import('./SalesManager/TargetSettings/AddTarget'))}/> */}
        <Route path={`${match.url}salesmanager-addtarget`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/TargetSettings/AddTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-edittarget/:id`} component={asyncComponent(() => import('./SalesManager/TargetSettings/EditTarget'))}/> */}
        <Route path={`${match.url}salesmanager-edittarget/:id`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/TargetSettings/EditTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-listnsskickerpendingapprovals`} component={asyncComponent(() => import('./SalesManager/PendingApprovals/NSSKicker'))}/> */}
        <Route path={`${match.url}salesmanager-listnsskickerpendingapprovals`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/NSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}salesmanager-viewkickerawaitinghistorydetails/:id`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/AwaitingApprovals/SMNSSKickerAwaitingHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        <Route path={`${match.url}salesmanager-listnsskickerawaitingapprovals`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/AwaitingApprovals'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* <Route path={`${match.url}salesmanager-listnsskickerhistory`} component={asyncComponent(() => import('./SalesManager/PendingApprovals/NSSKickerHistory'))}/> */}
        <Route path={`${match.url}salesmanager-listnsskickerhistory`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/NSSKickerHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-viewkickerhistorydetails/:id`} component={asyncComponent(() => import('./SalesManager/PendingApprovals/NSSKickerHistory/SMNSSKickerHistoryDetails'))}/>       */}
        <Route path={`${match.url}salesmanager-viewkickerhistorydetails/:id`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/NSSKickerHistory/SMNSSKickerHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-correctionslist`} component={asyncComponent(() => import('./SalesManager/PendingApprovals/CorrectionsList'))}/> */}
        <Route path={`${match.url}salesmanager-correctionslist`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/CorrectionsList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-datacorrectionpending/:id`} component={asyncComponent(() => import('./SalesManager/PendingApprovals/CorrectionsList/DataCorrectionSMPending'))}/> */}
        <Route path={`${match.url}salesmanager-datacorrectionpending/:id`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/CorrectionsList/DataCorrectionSMPending'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-correctionshistory`} component={asyncComponent(() => import('./SalesManager/PendingApprovals/CorrectionsHistory'))}/> */}
        <Route path={`${match.url}salesmanager-correctionshistory`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/CorrectionsHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-correctionhistorydetails/:id`} component={asyncComponent(() => import('./SalesManager/PendingApprovals/CorrectionsHistory/CorrectionHistoryDetails'))} /> */}
        <Route path={`${match.url}salesmanager-correctionhistorydetails/:id`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/CorrectionsHistory/CorrectionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-approveorrejectnsskicker/:id`} component={asyncComponent(() => import('./SalesManager/ApproveOrRejectNSSKicker'))}/> */}
        <Route path={`${match.url}salesmanager-approveorrejectnsskicker/:id`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/ApproveOrRejectNSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-listnsskickerrejected`} component={asyncComponent(() => import('./SalesManager/Rejections/NSSKicker'))}/> */}
        <Route path={`${match.url}salesmanager-listnsskickerrejected`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/Rejections/NSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-rejectedcorrectionslist`} component={asyncComponent(() => import('./SalesManager/Rejections/RejectedCorrectionsList'))}/> */}
        <Route path={`${match.url}salesmanager-rejectedcorrectionslist`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/Rejections/RejectedCorrectionsList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-incentivesimulator`} component={asyncComponent(() => import('./SalesManager/IncentiveSimulatorSM'))}/> */}
        <Route path={`${match.url}salesmanager-incentivesimulator`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/IncentiveSimulatorSM'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-pendingpayouts`} component={asyncComponent(() => import('./SalesManager/Payouts/PendingPayouts'))}/> */}
        <Route path={`${match.url}salesmanager-pendingpayouts`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/Payouts/PendingPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./SalesManager/Payouts/PendingPayoutDetails'))}/> */}
        <Route path={`${match.url}salesmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/Payouts/PendingPayoutDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}salesmanager-approvedpayouts`} component={asyncComponent(() => import('./SalesManager/Payouts/ApprovedPayouts'))}/> */}
        <Route path={`${match.url}salesmanager-approvedpayouts`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/Payouts/ApprovedPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* <Route path={`${match.url}salesmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./SalesManager/Payouts/CheckPayoutStatusDetails'))}/> */}
        <Route path={`${match.url}salesmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/Payouts/CheckPayoutStatusDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
          {/* ---------------------------Regional Manager over achievement exception ----------------------- */}
          <Route path={`${match.url}salesmanager-overachievementexception`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/OverAchievementException'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}salesmanager-overachievementexceptiondetails/:id/:quarter`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/OverAchievementException/OverAchievementExceptionDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}salesmanager-overachievementexceptionhistory`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/OverAchievementException/OverAchievementExceptionHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}salesmanager-overachievementexceptionhistorydetails/:id/:quarter`} component={asyncComponent(() => {
          if (["11"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SalesManager/PendingApprovals/OverAchievementException/OverAchievementExceptionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* VA Paths */}
        <Route path={`${match.url}va/salesmanagerdashboard`} component={asyncComponent(() => import('./SalesManager/VA/Dashboard'))} />
        <Route path={`${match.url}va/salesmanager-usersummary`} component={asyncComponent(() => import('./SalesManager/VA/UserSummary'))} />
        <Route path={`${match.url}va/salesmanager-individualsummary`} component={asyncComponent(() => import('./SalesManager/VA/UserSummary/IndividualSummary'))} />
        <Route path={`${match.url}va/salesmanager-kpisummary`} component={asyncComponent(() => import('./SalesManager/VA/UserSummary/KpiSummary'))} />
        <Route path={`${match.url}va/salesmanager-listtarget`} component={asyncComponent(() => import('./SalesManager/VA/TargetSettings/ListTarget'))} />
        <Route path={`${match.url}va/salesmanager-addtarget`} component={asyncComponent(() => import('./SalesManager/VA/TargetSettings/AddTarget'))} />
        <Route path={`${match.url}va/salesmanager-edittarget/:id`} component={asyncComponent(() => import('./SalesManager/VA/TargetSettings/EditTarget'))} />
        <Route path={`${match.url}va/salesmanager-sales`} component={asyncComponent(() => import('./SalesManager/VA/Sales/ListSales'))} />
        <Route path={`${match.url}va/salesmanager-addsale`} component={asyncComponent(() => import('./SalesManager/VA/Sales/AddSale'))} />
        <Route path={`${match.url}va/salesmanager-mycontracts`} component={asyncComponent(() => import('./SalesManager/VA/MyContracts'))} />
        <Route path={`${match.url}va/salesmanager-forecast`} component={asyncComponent(() => import('./SalesManager/VA/Forecast'))} />
        <Route path={`${match.url}va/salesmanager-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./SalesManager/VA/MyContracts'))} />
        <Route path={`${match.url}va/salesmanager-exception`} component={asyncComponent(() => import('./SalesManager/VA/Exception'))} />

        <Route path={`${match.url}va/salesmanager-mycontract-edit/:id`} component={asyncComponent(() => import('./SalesManager/VA/MyContracts/Edit/index'))} />

        <Route path={`${match.url}va/salesmanager-viewdetailedsale/:id/:year`} component={asyncComponent(() => import('./SalesManager/VA/Sales/AddSale/viewSale'))} />

        <Route path={`${match.url}va/salesmanager-editsale/:id`} component={asyncComponent(() => import('./SalesManager/VA/Sales/EditSale'))} />
        <Route path={`${match.url}va/salesmanager-viewdetailed/:id/:id1`} component={asyncComponent(() => import('./SalesManager/VA/Dashboard/detailedItem'))} />
        <Route path={`${match.url}va/salesmanager-incentivesimulator`} component={asyncComponent(() => import('./SalesManager/VA/IncentiveSimulatorSMVA'))} />
        <Route path={`${match.url}va/salesmanager-limbo`} component={asyncComponent(() => import('./SalesManager/VA/LimboSMVA'))} />
        <Route path={`${match.url}va/salesmanager-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./SalesManager/VA/LimboSMVA/LimboDetails'))} />
        <Route path={`${match.url}va/salesmanager-approvedpayouts`} component={asyncComponent(() => import('./SalesManager/VA/Payouts/ApprovedPayouts'))} />
        <Route path={`${match.url}va/salesmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./SalesManager/VA/Payouts/PendingPayoutDetails'))} />
        <Route path={`${match.url}va/salesmanager-pendingpayouts`} component={asyncComponent(() => import('./SalesManager/VA/Payouts/PendingPayouts'))} />
        <Route path={`${match.url}va/salesmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./SalesManager/VA/Payouts/CheckPayoutStatusDetails'))} />
        <Route path={`${match.url}va/salesmanager-rejectedsales`} component={asyncComponent(() => import('./SalesManager/VA/RejectedSales/ListSales'))} />
        {/* <Route path={`${match.url}va/salesmanager-rejectedsale/:id`} component={asyncComponent(() => import('./SalesManager/VA/RejectedSales/EditSale'))} /> */}
        <Route path={`${match.url}va/salesmanager-commissiondetailedreport`} component={asyncComponent(() => import('./SalesManager/VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/salesmanager-commissionsummaryreport`} component={asyncComponent(() => import('./SalesManager/VA/Report/CommissionSummaryReport'))} />

        <Route path={`${match.url}va/salesmanager-editrejectedsale/:id/:quarter/:year`} component={asyncComponent(() => import('./SalesManager/VA/RejectedSales/EditRejectedList'))} />
        {/* editrejectedsale */}
        <Route path={`${match.url}va/salesmanager-viewsale/:id/:year`} component={asyncComponent(() => import('./SalesManager/VA/Sales/ListSales/ListSaleDrawerView'))} />
        <Route path={`${match.url}va/salesmanager-viewtarget/:id`} component={asyncComponent(() => import('./SalesManager/VA/TargetSettings/ViewTarget'))} />
        <Route path={`${match.url}va/salesmanager-pendinglimbo/:id`} component={asyncComponent(() => import('./SalesManager/VA/LimboSMVA/PendingLimbo/LimboApprovalDetails'))} />        
        <Route path={`${match.url}va/salesmanager-pendinglimbo`} component={asyncComponent(() => import('./SalesManager/VA/LimboSMVA/PendingLimbo/index.js'))} />

        <Route path={`${match.url}va/salesmanager-awaitinglimboapprovals/:id`} component={asyncComponent(() => import('./SalesManager/VA/LimboSMVA/AwaitingLimbo/LimboAwaitingDetails'))} />
       <Route path={`${match.url}va/salesmanager-approvedlimboapprovals/:id`} component={asyncComponent(() => import('./SalesManager/VA/LimboSMVA/ApprovedLimbo/LimboApprovalDetails'))} />
       <Route path={`${match.url}va/salesmanager-approvedlimboapprovals`} component={asyncComponent(() => import('./SalesManager/VA/LimboSMVA/ApprovedLimbo'))} />
       <Route path={`${match.url}va/salesmanager-awaitinglimboapprovals`} component={asyncComponent(() => import('./SalesManager/VA/LimboSMVA/AwaitingLimbo'))} />
       
      </Switch>
      <Switch>
        {/* <Route path={`${match.url}regionalmanagerlanding`} component={asyncComponent(() => import('./RegionalManager/RegionalManagerLanding'))}/> */}
        <Route path={`${match.url}regionalmanagerlanding`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/RegionalManagerLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanagerdashboard`} component={asyncComponent(() => import('./RegionalManager/Dashboard'))}/> */}
        <Route path={`${match.url}regionalmanagerdashboard`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-mysales/:businessline/:projecttype`} component={asyncComponent(() => import('./RegionalManager/MySales/MySalesFilteredRM'))}/> */}
        <Route path={`${match.url}regionalmanager-mysales/:businessline/:projecttype`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/MySales/MySalesFilteredRM'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-mysales`} component={asyncComponent(() => import('./RegionalManager/MySales'))}/> */}
        <Route path={`${match.url}regionalmanager-mysales`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/MySales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-datacorrection/:id`} component={asyncComponent(() => import('./RegionalManager/MySales/DataCorrectionRM'))}/> */}
        <Route path={`${match.url}regionalmanager-datacorrection/:id`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/MySales/DataCorrectionRM'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-editcommission/:id`} component={asyncComponent(() => import('./RegionalManager/MySales/EditCommission'))}/> */}
        <Route path={`${match.url}regionalmanager-editcommission/:id`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/MySales/EditCommission'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-listtarget`} component={asyncComponent(() => import('./RegionalManager/TargetSettings/ListTarget'))}/> */}
        <Route path={`${match.url}regionalmanager-listtarget`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/TargetSettings/ListTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-addtarget`} component={asyncComponent(() => import('./RegionalManager/TargetSettings/AddTarget'))}/> */}
        <Route path={`${match.url}regionalmanager-addtarget`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/TargetSettings/AddTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-edittarget/:id`} component={asyncComponent(() => import('./RegionalManager/TargetSettings/EditTarget'))}/> */}
        <Route path={`${match.url}regionalmanager-edittarget/:id`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/TargetSettings/EditTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-listnsskickerpendingapprovals`} component={asyncComponent(() => import('./RegionalManager/PendingApprovals/NSSKicker'))}/> */}
        <Route path={`${match.url}regionalmanager-listnsskickerpendingapprovals`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/NSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}regionalmanager-listnsskickerawaitingapprovals`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/AwaitingApprovals'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}regionalmanager-viewkickerawaitinghistorydetails/:id`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/AwaitingApprovals/SMNSSKickerAwaitingHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-listnsskickerhistory`} component={asyncComponent(() => import('./RegionalManager/PendingApprovals/NSSKickerHistory'))}/> */}
        <Route path={`${match.url}regionalmanager-listnsskickerhistory`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/NSSKickerHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-viewkickerhistorydetails/:id`} component={asyncComponent(() => import('./RegionalManager/PendingApprovals/NSSKickerHistory/RMNSSKickerHistoryDetails'))}/>             */}
        <Route path={`${match.url}regionalmanager-viewkickerhistorydetails/:id`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/NSSKickerHistory/RMNSSKickerHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-changecommissionlist`} component={asyncComponent(() => import('./RegionalManager/PendingApprovals/ChangeCommissionList'))}/> */}
        <Route path={`${match.url}regionalmanager-changecommissionlist`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/ChangeCommissionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-correctionslist`} component={asyncComponent(() => import('./RegionalManager/PendingApprovals/CorrectionsList'))}/> */}
        <Route path={`${match.url}regionalmanager-correctionslist`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/CorrectionsList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-datacorrectionpending/:id`} component={asyncComponent(() => import('./RegionalManager/PendingApprovals/CorrectionsList/DataCorrectionRMPending'))}/>       */}
        <Route path={`${match.url}regionalmanager-datacorrectionpending/:id`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/CorrectionsList/DataCorrectionRMPending'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-correctionshistory`} component={asyncComponent(() => import('./RegionalManager/PendingApprovals/CorrectionsHistory'))}/> */}
        <Route path={`${match.url}regionalmanager-correctionshistory`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/CorrectionsHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-correctionhistorydetails/:id`} component={asyncComponent(() => import('./RegionalManager/PendingApprovals/CorrectionsHistory/CorrectionHistoryDetails'))} /> */}
        <Route path={`${match.url}regionalmanager-correctionhistorydetails/:id`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/CorrectionsHistory/CorrectionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
      {/* ---------------------------Regional Manager over achievement exception ----------------------- */}
          <Route path={`${match.url}regionalmanager-overachievementexception`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/OverAchievementException'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}regionalmanager-overachievementexceptiondetails/:id/:quarter`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/OverAchievementException/OverAchievementExceptionDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}regionalmanager-overachievementexceptionhistory`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/OverAchievementException/OverAchievementExceptionHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}regionalmanager-overachievementexceptionhistorydetails/:id/:quarter`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/PendingApprovals/OverAchievementException/OverAchievementExceptionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-approveorrejectnsskicker/:id`} component={asyncComponent(() => import('./RegionalManager/ApproveOrRejectNSSKicker'))}/> */}
        <Route path={`${match.url}regionalmanager-approveorrejectnsskicker/:id`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/ApproveOrRejectNSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-listnsskickerrejected`} component={asyncComponent(() => import('./RegionalManager/Rejections/NSSKicker'))}/> */}
        <Route path={`${match.url}regionalmanager-listnsskickerrejected`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/Rejections/NSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-rejectedcorrectionslist`} component={asyncComponent(() => import('./RegionalManager/Rejections/RejectedCorrectionsList'))}/> */}
        <Route path={`${match.url}regionalmanager-rejectedcorrectionslist`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/Rejections/RejectedCorrectionsList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-incentivesimulator`} component={asyncComponent(() => import('./RegionalManager/IncentiveSimulatorRM'))}/> */}
        <Route path={`${match.url}regionalmanager-incentivesimulator`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/IncentiveSimulatorRM'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-pendingpayouts`} component={asyncComponent(() => import('./RegionalManager/Payouts/PendingPayouts'))}/> */}
        <Route path={`${match.url}regionalmanager-pendingpayouts`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/Payouts/PendingPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./RegionalManager/Payouts/PendingPayoutDetails'))}/> */}
        <Route path={`${match.url}regionalmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/Payouts/PendingPayoutDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-approvedpayouts`} component={asyncComponent(() => import('./RegionalManager/Payouts/ApprovedPayouts'))}/> */}
        <Route path={`${match.url}regionalmanager-approvedpayouts`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/Payouts/ApprovedPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}regionalmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./RegionalManager/Payouts/CheckPayoutStatusDetails'))}/> */}
        <Route path={`${match.url}regionalmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => {
          if (["10"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalManager/Payouts/CheckPayoutStatusDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* VA Paths */}
        <Route path={`${match.url}va/regionalmanagerdashboard`} component={asyncComponent(() => import('./RegionalManager/VA/Dashboard'))} />
        <Route path={`${match.url}va/regionalmanager-listtarget`} component={asyncComponent(() => import('./RegionalManager/VA/TargetSettings/ListTarget'))} />
        <Route path={`${match.url}va/regionalmanager-addtarget`} component={asyncComponent(() => import('./RegionalManager/VA/TargetSettings/AddTarget'))} />
        <Route path={`${match.url}va/regionalmanager-sales`} component={asyncComponent(() => import('./RegionalManager/VA/Sales/ListSales'))} />
        <Route path={`${match.url}va/regionalmanager-addsale`} component={asyncComponent(() => import('./RegionalManager/VA/Sales/AddSale'))} />
        <Route path={`${match.url}va/regionalmanager-forecast`} component={asyncComponent(() => import('./RegionalManager/VA/Forecast'))} />
        <Route path={`${match.url}va/regionalmanager-editsale/:id`} component={asyncComponent(() => import('./RegionalManager/VA/Sales/EditSale'))} />
        <Route path={`${match.url}va/regionalmanager-mycontracts`} component={asyncComponent(() => import('./RegionalManager/VA/MyContracts'))} />
        <Route path={`${match.url}va/regionalmanager-mycontracts_usersummary`} component={asyncComponent(() => import('./RegionalManager/VA/MyContracts_UserSummary'))} />
        <Route path={`${match.url}va/regionalmanager-mycontract-edit/:id`} component={asyncComponent(() => import('./RegionalManager/VA/MyContracts/Edit/index'))} />
        <Route path={`${match.url}va/regionalmanager-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./RegionalManager/VA/MyContracts'))} />
        
        {/* <Route path={`${match.url}va/regionalmanager-viewdetailed/:id/:id1`} component={asyncComponent(()=>import('./RegionalManager/VA/Dashboard/detailedItem'))}/> */}
        <Route path={`${match.url}va/regionalmanager-viewsale/:id`} component={asyncComponent(() => import('./RegionalManager/VA/Sales/ListSales/ListSaleDrawerView'))} />
        <Route path={`${match.url}va/regionalmanager-approvedpayouts`} component={asyncComponent(() => import('./RegionalManager/VA/Payouts/ApprovedPayouts'))} />
        <Route path={`${match.url}va/regionalmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./RegionalManager/VA/Payouts/PendingPayoutDetails'))} />
        <Route path={`${match.url}va/regionalmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./RegionalManager/VA/Payouts/CheckPayoutStatusDetails'))} />
        <Route path={`${match.url}va/regionalmanager-pendingpayouts`} component={asyncComponent(() => import('./RegionalManager/VA/Payouts/PendingPayouts'))} />
        <Route path={`${match.url}va/regionalmanager-incentivesimulator`} component={asyncComponent(() => import('./RegionalManager/VA/IncentiveSimulatorRMVA'))} />

        <Route path={`${match.url}va/regionalmanager-edittarget/:id`} component={asyncComponent(() => import('./RegionalManager/VA/TargetSettings/EditTarget'))} />

        <Route path={`${match.url}va/regionalmanager-commissiondetailedreport`} component={asyncComponent(() => import('./RegionalManager/VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/regionalmanager-commissionsummaryreport`} component={asyncComponent(() => import('./RegionalManager/VA/Report/CommissionSummaryReport'))} />

        <Route path={`${match.url}va/regionalmanager-viewdetailedsale/:id/:year`} component={asyncComponent(() => import('./RegionalManager/VA/Sales/AddSale/viewSale'))} />
        <Route path={`${match.url}va/regionalmanager-usersummary`} component={asyncComponent(() => import('./RegionalManager/VA/UserSummary'))} />
        <Route path={`${match.url}va/regionalmanager-individualsummary`} component={asyncComponent(() => import('./RegionalManager/VA/UserSummary/IndividualSummary'))} />
        <Route path={`${match.url}va/regionalmanager-kpisummary`} component={asyncComponent(() => import('./RegionalManager/VA/UserSummary/KpiSummary'))} />
        <Route path={`${match.url}va/regionalmanager-viewtarget/:id`} component={asyncComponent(() => import('./RegionalManager/VA/TargetSettings/ViewTarget'))} />
        <Route path={`${match.url}va/regionalmanager-viewdetailedtarget`} component={asyncComponent(() => import('./RegionalManager/VA/TargetSettings/ViewDetailedTarget'))} />
        <Route path={`${match.url}va/regionalmanager-pendinglimboapprovals/:id`} component={asyncComponent(() => import('./RegionalManager/VA/LimboSMVA/PendingLimbo/LimboApprovalDetails'))} />        
        <Route path={`${match.url}va/regionalmanager-pendinglimboapprovals`} component={asyncComponent(() => import('./RegionalManager/VA/LimboSMVA/PendingLimbo/index.js'))} />

        <Route path={`${match.url}va/regionalmanager-limbo`} component={asyncComponent(() => import('./RegionalManager/VA/LimboSMVA'))} />
        <Route path={`${match.url}va/regionalmanager-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./RegionalManager/VA/LimboSMVA/LimboDetails'))} />

        <Route path={`${match.url}va/regionalmanager-awaitinglimboapprovals/:id`} component={asyncComponent(() => import('./RegionalManager/VA/LimboSMVA/AwaitingLimbo/LimboAwaitingDetails'))} />
        <Route path={`${match.url}va/regionalmanager-approvedlimboapprovals/:id`} component={asyncComponent(() => import('./RegionalManager/VA/LimboSMVA/ApprovedLimbo/LimboApprovalDetails'))} />
        <Route path={`${match.url}va/regionalmanager-approvedlimboapprovals`} component={asyncComponent(() => import('./RegionalManager/VA/LimboSMVA/ApprovedLimbo'))} />
       <Route path={`${match.url}va/regionalmanager-awaitinglimboapprovals`} component={asyncComponent(() => import('./RegionalManager/VA/LimboSMVA/AwaitingLimbo'))} />
      

      </Switch>
      <Switch>
        {/* <Route path={`${match.url}hrmanagerlanding`} component={asyncComponent(() => import('./HRManager/HRMLanding'))}/> */}
        <Route path={`${match.url}hrmanagerlanding`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/HRMLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanagerdashboard`} component={asyncComponent(() => import('./HRManager/Dashboard'))}/> */}
        <Route path={`${match.url}hrmanagerdashboard`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-sales`} component={asyncComponent(() => import('./HRManager/Sales'))}/> */}
        <Route path={`${match.url}hrmanager-sales`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Sales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-pendingpayouts`} component={asyncComponent(() => import('./HRManager/Payouts/PendingPayouts'))}/> */}
        <Route path={`${match.url}hrmanager-pendingpayouts`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Payouts/PendingPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./HRManager/Payouts/PendingPayoutDetails'))}/> */}
        <Route path={`${match.url}hrmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Payouts/PendingPayoutDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-approvedpayouts`} component={asyncComponent(() => import('./HRManager/Payouts/ApprovedPayouts'))}/> */}
        <Route path={`${match.url}hrmanager-approvedpayouts`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Payouts/ApprovedPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./HRManager/Payouts/CheckPayoutStatusDetails'))}/>       */}
        <Route path={`${match.url}hrmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Payouts/CheckPayoutStatusDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-payoutreport`} component={asyncComponent(() => import('./HRManager/PayoutReport'))}/> */}
        <Route path={`${match.url}hrmanager-payoutreport`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/PayoutReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-changecommissionreport`} component={asyncComponent(() => import('./HRManager/Report/ChangeCommissionReport'))}/> */}
        <Route path={`${match.url}hrmanager-changecommissionreport`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Report/ChangeCommissionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-correctionreport`} component={asyncComponent(() => import('./HRManager/Report/CorrectionReport'))}/> */}
        <Route path={`${match.url}hrmanager-correctionreport`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Report/CorrectionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-payoutstatusreport`} component={asyncComponent(() => import('./HRManager/Report/PayoutStatusReport'))}/> */}
        <Route path={`${match.url}hrmanager-payoutstatusreport`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Report/PayoutStatusReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}hrmanager-targetdistributorreport`} component={asyncComponent(() => import('./HRManager/Report/TargetDistributorReport'))}/> */}
        <Route path={`${match.url}hrmanager-targetdistributorreport`} component={asyncComponent(() => {
          if (["9"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./HRManager/Report/TargetDistributorReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* VA Paths */}
        <Route path={`${match.url}va/hrmanagerdashboard`} component={asyncComponent(() => import('./HRManager/VA/Dashboard'))} />
        <Route path={`${match.url}va/hrmanager-approvedpayouts`} component={asyncComponent(() => import('./HRManager/VA/Payouts/ApprovedPayouts'))} />
        <Route path={`${match.url}va/hrmanager-pendingpayouts`} component={asyncComponent(() => import('./HRManager/VA/Payouts/PendingPayouts'))} />
        <Route path={`${match.url}va/hrmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./HRManager/VA/Payouts/PendingPayoutDetails'))} />
        <Route path={`${match.url}va/hrmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./HRManager/VA/Payouts/CheckPayoutStatusDetails'))} />
        <Route path={`${match.url}va/hrmanager-commissiondetailedreport`} component={asyncComponent(() => import('./HRManager/VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/hrmanager-commissionsummaryreport`} component={asyncComponent(() => import('./HRManager/VA/Report/CommissionSummaryReport'))} />
        <Route path={`${match.url}va/hrmanager-targetdistributorreport`} component={asyncComponent(() => import('./HRManager/VA/Report/TargetDistributorReport'))} />
        <Route path={`${match.url}va/hrmanager-payoutstatusreport`} component={asyncComponent(() => import('./HRManager/VA/Report/PayoutStatusReport'))} />
        <Route path={`${match.url}va/hrmanager-payoutreport`} component={asyncComponent(() => import('./HRManager/VA/Report/PayoutReport'))} />


      </Switch>
      <Switch>
        {/* <Route path={`${match.url}mdlanding`} component={asyncComponent(() => import('./MD/MDLanding'))}/> */}
        <Route path={`${match.url}mdlanding`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/MDLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}mddashboard`} component={asyncComponent(() => import('./MD/Dashboard'))}/> */}
        <Route path={`${match.url}mddashboard`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-sales`} component={asyncComponent(() => import('./MD/Sales'))}/> */}
        <Route path={`${match.url}md-sales`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Sales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-editcommission/:id`} component={asyncComponent(() => import('./MD/Sales/EditCommission'))}/>       */}
        <Route path={`${match.url}md-editcommission/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Sales/EditCommission'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-cvexceptionsales`} component={asyncComponent(() => import('./MD/CVExceptionSales'))}/> */}
        <Route path={`${match.url}md-cvexceptionsales`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/CVExceptionSales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        <Route path={`${match.url}md-exceptionsale`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Rejections/ExceptionSalesList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />


        {/* <Route path={`${match.url}md-editexceptioncommission/:id`} component={asyncComponent(() => import('./MD/CVExceptionSales/EditCommission'))}/>       */}
        <Route path={`${match.url}md-editexceptioncommission/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/CVExceptionSales/EditCommission'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-listnsskickerpendingapprovals`} component={asyncComponent(() => import('./MD/PendingApprovals/NSSKicker'))}/> */}
        <Route path={`${match.url}md-listnsskickerpendingapprovals`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/NSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-listnsskickerhistory`} component={asyncComponent(() => import('./MD/PendingApprovals/NSSKickerHistory'))}/> */}
        <Route path={`${match.url}md-listnsskickerhistory`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/NSSKickerHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-viewkickerhistorydetails/:id`} component={asyncComponent(() => import('./MD/PendingApprovals/NSSKickerHistory/MDNSSKickerHistoryDetails'))}/>                   */}
        <Route path={`${match.url}md-viewkickerhistorydetails/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/NSSKickerHistory/MDNSSKickerHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-changecommissionlist`} component={asyncComponent(() => import('./MD/PendingApprovals/ChangeCommissionList'))}/> */}
        <Route path={`${match.url}md-changecommissionlist`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/ChangeCommissionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}md-awaitingcommisssionexceptions`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/AwaitingCommissionExceptionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}md-editawaitingcommissionpending/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/AwaitingCommissionExceptionList/EditCommissionPending'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* <Route path={`${match.url}md-editcommissionpending/:id`} component={asyncComponent(() => import('./MD/PendingApprovals/ChangeCommissionList/EditCommissionPending'))}/>       */}
        <Route path={`${match.url}md-editcommissionpending/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/ChangeCommissionList/EditCommissionPending'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-commissionlisthistory`} component={asyncComponent(() => import('./MD/PendingApprovals/CommissionListHistory'))}/> */}
        <Route path={`${match.url}md-commissionlisthistory`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/CommissionListHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-viewcommissionhistorydetails/:id`} component={asyncComponent(() => import('./MD/PendingApprovals/CommissionListHistory/CommissionHistoryDetails'))}/>                   */}
        <Route path={`${match.url}md-viewcommissionhistorydetails/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/CommissionListHistory/CommissionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-approveorrejectnsskicker/:id`} component={asyncComponent(() => import('./MD/ApproveOrRejectNSSKicker'))}/> */}
        <Route path={`${match.url}md-approveorrejectnsskicker/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/ApproveOrRejectNSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-listnsskickerrejected`} component={asyncComponent(() => import('./MD/Rejections/NSSKicker'))}/> */}
        <Route path={`${match.url}md-listnsskickerrejected`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Rejections/NSSKicker'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-rejectedcommissionlist`} component={asyncComponent(() => import('./MD/Rejections/RejectedCommissionList'))}/> */}
        <Route path={`${match.url}md-rejectedcommissionlist`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Rejections/RejectedCommissionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-editcommissionrejected/:id`} component={asyncComponent(() => import('./MD/Rejections/RejectedCommissionList/EditCommissionRejected'))}/>       */}
        <Route path={`${match.url}md-editcommissionrejected/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Rejections/RejectedCommissionList/EditCommissionRejected'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-rejectedcorrectionslist`} component={asyncComponent(() => import('./MD/Rejections/RejectedCorrectionsList'))}/> */}
        <Route path={`${match.url}md-rejectedcorrectionslist`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Rejections/RejectedCorrectionsList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-datacorrectionpending/:id`} component={asyncComponent(() => import('./MD/PendingApprovals/CorrectionsList/DataCorrectionMD'))}/> */}
        <Route path={`${match.url}md-datacorrectionpending/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/CorrectionsList/DataCorrectionMD'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-correctionslist`} component={asyncComponent(() => import('./MD/PendingApprovals/CorrectionsList'))}/> */}
        <Route path={`${match.url}md-correctionslist`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/CorrectionsList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-correctionshistory`} component={asyncComponent(() => import('./MD/PendingApprovals/CorrectionsHistory'))}/> */}
        <Route path={`${match.url}md-correctionshistory`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/CorrectionsHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-correctionhistorydetails/:id`} component={asyncComponent(() => import('./MD/PendingApprovals/CorrectionsHistory/CorrectionHistoryDetails'))} /> */}
        <Route path={`${match.url}md-correctionhistorydetails/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/CorrectionsHistory/CorrectionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-listtarget`} component={asyncComponent(() => import('./MD/TargetSettings/ListTarget'))}/> */}
        <Route path={`${match.url}md-listtarget`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/TargetSettings/ListTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}md-viewtarget/:id`} component={asyncComponent(() => import('./MD/TargetSettings/ViewTarget'))} />
        {/* md approval drilldown routes */}
        <Route path={`${match.url}md-view/:year/:businessLineName/:projectType/:geographicalLevelNames/:id`} component={asyncComponent(() => import('./MD/TargetSettings/ApproveTarget'))} />

        {/* <Route path={`${match.url}md-addtarget`} component={asyncComponent(() => import('./MD/TargetSettings/AddTarget'))}/> */}
        <Route path={`${match.url}md-addtarget`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/TargetSettings/AddTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-edittarget/:id`} component={asyncComponent(() => import('./MD/TargetSettings/EditTarget'))}/>       */}
        <Route path={`${match.url}md-edittarget/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/TargetSettings/EditTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-incentivesimulator`} component={asyncComponent(() => import('./MD/IncentiveSimulatorMD'))}/> */}
        <Route path={`${match.url}md-incentivesimulator`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/IncentiveSimulatorMD'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-pendingpayouts`} component={asyncComponent(() => import('./MD/Payouts/PendingPayouts'))}/> */}
        <Route path={`${match.url}md-pendingpayouts`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Payouts/PendingPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./MD/Payouts/PendingPayoutDetails'))}/> */}
        <Route path={`${match.url}md-pendingpayout/:id/:quarter`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Payouts/PendingPayoutDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-approvedpayouts`} component={asyncComponent(() => import('./MD/Payouts/ApprovedPayouts'))}/> */}
        <Route path={`${match.url}md-approvedpayouts`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Payouts/ApprovedPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./MD/Payouts/CheckPayoutStatusDetails'))}/>       */}
        <Route path={`${match.url}md-checkstatus/:id/:quarter/`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Payouts/CheckPayoutStatusDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-payoutreport`} component={asyncComponent(() => import('./MD/PayoutReport'))}/> */}
        <Route path={`${match.url}md-payoutreport`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PayoutReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-changecommissionreport`} component={asyncComponent(() => import('./MD/Report/ChangeCommissionReport'))}/> */}
        <Route path={`${match.url}md-changecommissionreport`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Report/ChangeCommissionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-correctionreport`} component={asyncComponent(() => import('./MD/Report/CorrectionReport'))}/> */}
        <Route path={`${match.url}md-correctionreport`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Report/CorrectionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-payoutstatusreport`} component={asyncComponent(() => import('./MD/Report/PayoutStatusReport'))}/> */}
        <Route path={`${match.url}md-payoutstatusreport`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Report/PayoutStatusReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}md-targetdistributorreport`} component={asyncComponent(() => import('./MD/Report/TargetDistributorReport'))}/> */}
        <Route path={`${match.url}md-targetdistributorreport`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/Report/TargetDistributorReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
           {/* ---------------------------MD over achievement exception ----------------------- */}
           <Route path={`${match.url}md-overachievementexception`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/OverAchievementException'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}md-overachievementexceptiondetails/:id/:quarter`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/OverAchievementException/OverAchievementExceptionDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}md-overachievementexceptionhistory`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/OverAchievementException/OverAchievementExceptionHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}md-overachievementexceptionhistorydetails/:id/:quarter`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/PendingApprovals/OverAchievementException/OverAchievementExceptionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        { /* VA Paths */}
        <Route path={`${match.url}va/mddashboard`} component={asyncComponent(() => import('./MD/VA/Dashboard'))} />
        <Route path={`${match.url}va/md-usersummary`} component={asyncComponent(() => import('./MD/VA/UserSummary'))} />
        <Route path={`${match.url}va/md-individualsummary`} component={asyncComponent(() => import('./MD/VA/UserSummary/IndividualSummary'))} />
        <Route path={`${match.url}va/md-kpisummary`} component={asyncComponent(() => import('./MD/VA/UserSummary/KpiSummary'))} />
        <Route path={`${match.url}va/md-listtarget`} component={asyncComponent(() => import('./MD/VA/TargetSettings/ListTarget'))} />
        <Route path={`${match.url}va/md-addtarget`} component={asyncComponent(() => import('./MD/VA/TargetSettings/AddTarget'))} />
        <Route path={`${match.url}va/md-edittarget/:id`} component={asyncComponent(() => import('./MD/VA/TargetSettings/EditTarget'))} />
        <Route path={`${match.url}va/md-approvedpayouts`} component={asyncComponent(() => import('./MD/VA/Payouts/ApprovedPayouts'))} />
        <Route path={`${match.url}va/md-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./MD/VA/Payouts/PendingPayoutDetails'))} />
        <Route path={`${match.url}va/md-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./MD/VA/Payouts/CheckPayoutStatusDetails'))} />
        <Route path={`${match.url}va/md-pendingpayouts`} component={asyncComponent(() => import('./MD/VA/Payouts/PendingPayouts'))} />
        <Route path={`${match.url}va/md-changecommission`} component={asyncComponent(() => import('./MD/VA/Exceptions/ChangeCommission'))} />
        <Route path={`${match.url}va/md-changecommissionapprove/:year/:quarter/:geographicallevel/:id/:businessline/:projecttype/:yeartext/:subprojecttype`} component={asyncComponent(() => import('./MD/VA/Exceptions/ChangeCommissionApprove/index'))} />
        <Route path={`${match.url}va/md-collectioncommission`} component={asyncComponent(() => import('./MD/VA/Exceptions/CollectionCommission/CollectionCommission'))} />
        <Route path={`${match.url}va/md-collectioncommissionapprove/:year/:quarter/:geographicallevel/:id/:businessline/:projecttype/:yeartext/:roleid`} component={asyncComponent(() => import('./MD/VA/Exceptions/CollectionCommission/ChangeCollectionCommissionApprove'))} />
        <Route path={`${match.url}va/md-commissiondetailedreport`} component={asyncComponent(() => import('./MD/VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/md-commissionsummaryreport`} component={asyncComponent(() => import('./MD/VA/Report/CommissionSummaryReport'))} />
        <Route path={`${match.url}va/md-targetdistributorreport`} component={asyncComponent(() => import('./MD/VA/Report/TargetDistributorReport'))} />
        <Route path={`${match.url}va/md-payoutstatusreport`} component={asyncComponent(() => import('./MD/VA/Report/PayoutStatusReport'))} />
        <Route path={`${match.url}va/md-payoutreport`} component={asyncComponent(() => import('./MD/VA/Report/PayoutReport'))} />
        <Route path={`${match.url}va/md-mycontracts`} component={asyncComponent(() => import('./MD/VA/MyContracts'))} />
        <Route path={`${match.url}va/md-targetapproval`} component={asyncComponent(() => import('./MD/VA/Approvals/TargetApproval/ListTarget'))} />
        <Route path={`${match.url}va/md-pendinglimboapprovals/:id`} component={asyncComponent(() => import('./MD/VA/LimboSMVA/PendingLimbo/LimboApprovalDetails'))} />        
        <Route path={`${match.url}va/md-limbo`} component={asyncComponent(() => import('./MD/VA/LimboSMVA'))} /> 
        <Route path={`${match.url}va/md-forecast`} component={asyncComponent(() => import('./MD/VA/Forecast'))} />
        <Route path={`${match.url}va/md-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./MD/VA/LimboSMVA/LimboDetails'))} />
        <Route path={`${match.url}va/md-pendinglimboapprovals`} component={asyncComponent(() => import('./MD/VA/LimboSMVA/PendingLimbo/index.js'))} />
        <Route path={`${match.url}va/md-awaitinglimboapprovals/:id`} component={asyncComponent(() => import('./MD/VA/LimboSMVA/AwaitingLimbo/LimboAwaitingDetails'))} />
       <Route path={`${match.url}va/md-approvedlimboapprovals/:id`} component={asyncComponent(() => import('./MD/VA/LimboSMVA/ApprovedLimbo/LimboApprovalDetails'))} />
       <Route path={`${match.url}va/md-approvedlimboapprovals`} component={asyncComponent(() => import('./MD/VA/LimboSMVA/ApprovedLimbo'))} />
       <Route path={`${match.url}va/md-awaitinglimboapprovals`} component={asyncComponent(() => import('./MD/VA/LimboSMVA/AwaitingLimbo'))} />
          {/* ---------------------------MD over achievement exception ----------------------- */}
       <Route path={`${match.url}va/md-overachievementexception`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/VA/Approvals/OverAchievementException'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}va/md-overachievementexceptiondetails`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/VA/Approvals/OverAchievementException/OverAchievementExceptionDetails');}
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}va/md-overachievementexceptionhistory`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/VA/Approvals/OverAchievementException/OverAchievementExceptionHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}va/md-overachievementexceptionhistorydetails/:id`} component={asyncComponent(() => {
          if (["8"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./MD/VA/Approvals/OverAchievementException/OverAchievementExceptionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        <Route path={`${match.url}va/md-viewtarget/:id`} component={asyncComponent(() => import('./MD/VA/TargetSettings/ViewTarget'))} />
        <Route path={`${match.url}va/md-viewtargetapproval/:id`} component={asyncComponent(() => import('./MD/VA/Approvals/TargetApproval/ViewTarget'))} />

        {/* md approval drilldown routes */}
        <Route path={`${match.url}va/md-view/:year/:businessLineName/:projectType/:subprojectType/:geographicalLevelNames/:id`} component={asyncComponent(() => import('./MD/VA/TargetSettings/ApproveTarget'))} />
      </Switch>
      <Switch>
        {/* <Route path={`${match.url}sdmlanding`} component={asyncComponent(() => import('./SDM/SDMLanding'))}/> */}
        <Route path={`${match.url}sdmlanding`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/SDMLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanagerdashboard`} component={asyncComponent(() => import('./AreaBusinesslineManager/Dashboard'))}/> */}
        <Route path={`${match.url}sdmdashboard`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}sdmusers`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/Users/ListUsers'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}sdm-sales`} component={asyncComponent(() => import('./SDM/Sales'))}/> */}
        <Route path={`${match.url}sdm-sales`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/Sales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
           <Route path={`${match.url}sdm-exception`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/Sales/Exception'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}sdm-qlicksensesales`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/QlickSenseSales/QlickSenseList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}sdm-qlicksenseduplicatesales`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/QlickSenseSales/QlickSenseDuplicateList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

         <Route path={`${match.url}sdm-qlicksenseexception`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/QlickSenseSales/Exception'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
          <Route path={`${match.url}sdm-edit-qlicksense-ready-to-process/:id`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/QlickSenseSales/QlickSenseList/Edit'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}sdm-edit-qlicksense-exceptions/:id`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/QlickSenseSales/Exception/Edit'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}sdm-changecommissionreport`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/Report/ChangeCommissionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}sdm-correctionreport`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/Report/CorrectionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}sdm-targetdistributorreport`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/Report/TargetDistributorReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}sdm-payoutstatusreport`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/Report/PayoutStatusReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}sdm-report`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/Report'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}sdm-dataanalytics`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/DataAnalytics/MenuOptionsPage'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Analytics Routes */}
        <Route path={`${match.url}sdm-frontlineperformance`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/DataAnalytics/FrontLinePerformance'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}sdm-salespersonsperformance`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/DataAnalytics/SalesPersonsPerformance'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}sdm-salespersonflcombined`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/DataAnalytics/SalesPersonFLCombined'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}sdm-analytics/settings`} component={asyncComponent(() => {
          if (["7"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./SDM/DataAnalytics/Settings'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* VA Paths */}
        <Route path={`${match.url}va/sdm-usersummary`} component={asyncComponent(() => import('./SDM/VA/UserSummary'))} />
        <Route path={`${match.url}va/sdm-individualsummary`} component={asyncComponent(() => import('./SDM/VA/UserSummary/IndividualSummary'))} />
        <Route path={`${match.url}va/sdm-kpisummary`} component={asyncComponent(() => import('./SDM/VA/UserSummary/KpiSummary'))} />
        <Route path={`${match.url}va/sdm-mycontracts`} component={asyncComponent(() => import('./SDM/VA/MyContracts'))} />
        <Route path={`${match.url}va/sdm-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./SDM/VA/MyContracts'))} />
        <Route path={`${match.url}va/sdm-mycontract-edit/:id`} component={asyncComponent(() => import('./SDM/VA/MyContracts/Edit/index'))} />
        <Route path={`${match.url}va/sdmdashboard`} component={asyncComponent(() => import('./SDM/VA/Dashboard'))} />
        <Route path={`${match.url}va/sdmfinancialapproval`} component={asyncComponent(() => import('./SDM/VA/FinancialApproval/ApprovedFinancialList'))} />
        <Route path={`${match.url}va/sdm-checkstatus/:id/:projecttype/:quarter/:year`} component={asyncComponent(() => import('./SDM/VA/FinancialApproval/ApprovedFinancialDetails'))} />
        <Route path={`${match.url}va/sdm-commissiondetailedreport`} component={asyncComponent(() => import('./SDM/VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/sdm-commissionsummaryreport`} component={asyncComponent(() => import('./SDM/VA/Report/CommissionSummaryReport'))} />
        <Route path={`${match.url}va/sdm-targetdistributorreport`} component={asyncComponent(() => import('./SDM/VA/Report/TargetDistributorReport'))} />
        <Route path={`${match.url}va/sdm-payoutstatusreport`} component={asyncComponent(() => import('./SDM/VA/Report/PayoutStatusReport'))} />
        <Route path={`${match.url}va/sdm-payoutreport`} component={asyncComponent(() => import('./SDM/VA/Report/PayoutReport'))} />
        <Route path={`${match.url}va/sdmusers`} component={asyncComponent(() => import('./SDM/VA/Users/ListUsers')) }/>
        <Route path={`${match.url}va/sdmincentive`} component={asyncComponent(() => import('./SDM/VA/IncentiveProcessing')) }/>
        <Route path={`${match.url}va/sdm-contracthistory`} component={asyncComponent(() => import('./SDM/VA/ContractUploadHistory')) }/>
        <Route path={`${match.url}va/kbs-contracthistory`} component={asyncComponent(() => import('./KBSUser/VA/ContractUploadHistory')) }/>
        <Route path={`${match.url}va/sdm-contractupload`} component={asyncComponent(() => import('./SDM/VA/ContractUpload'))} />
        <Route path={`${match.url}va/sdm-forecast`} component={asyncComponent(() => import('./SDM/VA/Forecast'))} />
        <Route path={`${match.url}va/sdm-kmta`} component={asyncComponent(() => import('./SDM/VA/UserSummary/KMTA/index'))} />
        <Route path={`${match.url}va/sdm-allindividual-usersummary`} component={asyncComponent(() => import('./SDM/VA/UserSummary/AllIndividualSummary/BranchSummary/index.js'))} />
        <Route path={`${match.url}va/sdm-allindividual-kpisummary`} component={asyncComponent(() => import('./SDM/VA/UserSummary/AllIndividualSummary/KpiSummary'))} />
        {/* Tickets */}
        <Route path={`${match.url}sdm-tickets`} component={asyncComponent(() => import('./SDM/Tickets/index'))} />
        <Route path={`${match.url}sdm-tickets-viewticket/:id`} component={asyncComponent(() => import('./SDM/Tickets/ViewTicket.js'))} />
        {/* Limbo */}
        <Route path={`${match.url}va/sdm-limbo-details/:project_type/:id/:tablename/:columnname/:kmta`} component={asyncComponent(() => import('./SDM/VA/LimboSMVA/LimboDetails'))} />
        <Route path={`${match.url}va/sdm-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./SDM/VA/LimboSMVA/LimboDetails'))} />
        <Route path={`${match.url}va/sdm-limboapprovals/:id`} component={asyncComponent(() => import('./SDM/VA/LimboSMVA/LimboApprovalDetails/index.js'))} />
        <Route path={`${match.url}va/sdm-limbo`} component={asyncComponent(() => import('./SDM/VA/LimboSMVA'))} />
        <Route path={`${match.url}va/sdm-profitability-details`} component={asyncComponent(() => import('./SDM/VA/Profitability/ProfitabilityDetails'))} />
        <Route path={`${match.url}va/sdm-profitability`} component={asyncComponent(() => import('./SDM/VA/Profitability/index.js'))} />  
        <Route path={`${match.url}va/sdm-billingblock`} component={asyncComponent(() => import('./SDM/VA/BillingBlockSMVA'))} /> 
        <Route path={`${match.url}va/sdm-billingblock-details/:id/:tablename/:columnname/:kmta`} component={asyncComponent(() => import('./SDM/VA/BillingBlockSMVA/BillingBlockDetails'))} />
        <Route path={`${match.url}va/sdm-billingblock-details/:id/:tablename/:columnname`} component={asyncComponent(() => import('./SDM/VA/BillingBlockSMVA/BillingBlockDetails'))} />
        <Route path={`${match.url}va/sdm-mso`} component={asyncComponent(() => import('./SDM/VA/MSOSMVA'))} /> 
        <Route path={`${match.url}va/sdm-mso-details/:id/:tablename/:columnname/:kmta`} component={asyncComponent(() => import('./SDM/VA/MSOSMVA/MSODetails'))} />
        <Route path={`${match.url}va/sdm-mso-details/:id/:tablename/:columnname`} component={asyncComponent(() => import('./SDM/VA/MSOSMVA/MSODetails'))} />

      </Switch>
      <Switch>
    
        {/* <Route path={`${match.url}areabusinesslinemanagerlanding`} component={asyncComponent(() => import('./AreaBusinesslineManager/ABLMLanding'))}/> */}
        <Route path={`${match.url}areabusinesslinemanagerlanding`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/ABLMLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanagerdashboard`} component={asyncComponent(() => import('./AreaBusinesslineManager/Dashboard'))}/> */}
        <Route path={`${match.url}areabusinesslinemanagerdashboard`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-sales`} component={asyncComponent(() => import('./AreaBusinesslineManager/Sales'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-sales`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/Sales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-editcommission/:id`} component={asyncComponent(() => import('./AreaBusinesslineManager/Sales/EditCommission'))}/>       */}
        <Route path={`${match.url}areabusinesslinemanager-editcommission/:id`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/Sales/EditCommission'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-changecommissionlist`} component={asyncComponent(() => import('./AreaBusinesslineManager/PendingApprovals/ChangeCommissionList'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-changecommissionlist`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/PendingApprovals/ChangeCommissionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-editcommissionpending/:id`} component={asyncComponent(() => import('./AreaBusinesslineManager/PendingApprovals/ChangeCommissionList/EditCommissionPending'))}/>       */}
        <Route path={`${match.url}areabusinesslinemanager-editcommissionpending/:id`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/PendingApprovals/ChangeCommissionList/EditCommissionPending'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areabusinesslinemanager-editawaitingcommissionpending/:id`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/PendingApprovals/AwaitingCommissionExceptionList/EditCommissionPending'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-commissionlisthistory`} component={asyncComponent(() => import('./AreaBusinesslineManager/PendingApprovals/CommissionListHistory'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-commissionlisthistory`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/PendingApprovals/CommissionListHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        <Route path={`${match.url}areabusinesslinemanager-awaitingcommisssionexceptions`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/PendingApprovals/AwaitingCommissionExceptionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />


        {/* <Route path={`${match.url}areabusinesslinemanager-viewcommissionhistorydetails/:id`} component={asyncComponent(() => import('./AreaBusinesslineManager/PendingApprovals/CommissionListHistory/CommissionHistoryDetails'))}/>                         */}
        <Route path={`${match.url}areabusinesslinemanager-viewcommissionhistorydetails/:id`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/PendingApprovals/CommissionListHistory/CommissionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-rejectedcommissionlist`} component={asyncComponent(() => import('./AreaBusinesslineManager/Rejections/RejectedCommissionList'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-rejectedcommissionlist`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/Rejections/RejectedCommissionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-viewcommissionrejected/:id`} component={asyncComponent(() => import('./AreaBusinesslineManager/Rejections/RejectedCommissionList/EditCommissionRejected'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-viewcommissionrejected/:id`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/Rejections/RejectedCommissionList/EditCommissionRejected'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-listtarget`} component={asyncComponent(() => import('./AreaBusinesslineManager/TargetSettings/ListTarget'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-listtarget`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/TargetSettings/ListTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-addtarget`} component={asyncComponent(() => import('./AreaBusinesslineManager/TargetSettings/AddTarget'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-addtarget`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/TargetSettings/AddTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-edittarget/:id`} component={asyncComponent(() => import('./AreaBusinesslineManager/TargetSettings/EditTarget'))}/>       */}
        <Route path={`${match.url}areabusinesslinemanager-edittarget/:id`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/TargetSettings/EditTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-incentivesimulator`} component={asyncComponent(() => import('./AreaBusinesslineManager/IncentiveSimulatorABLM'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-incentivesimulator`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/IncentiveSimulatorABLM'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-changecommissionreport`} component={asyncComponent(() => import('./AreaBusinesslineManager/Report/ChangeCommissionReport'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-changecommissionreport`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/Report/ChangeCommissionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-correctionreport`} component={asyncComponent(() => import('./AreaBusinesslineManager/Report/CorrectionReport'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-correctionreport`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/Report/CorrectionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-payoutstatusreport`} component={asyncComponent(() => import('./AreaBusinesslineManager/Report/PayoutStatusReport'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-payoutstatusreport`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/Report/PayoutStatusReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areabusinesslinemanager-targetdistributorreport`} component={asyncComponent(() => import('./AreaBusinesslineManager/Report/TargetDistributorReport'))}/> */}
        <Route path={`${match.url}areabusinesslinemanager-targetdistributorreport`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/Report/TargetDistributorReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Analytics Routes */}
        <Route path={`${match.url}areabusinesslinemanager-dataanalytics`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/DataAnalytics/MenuOptionsPage'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areabusinesslinemanager-frontlineperformance`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/DataAnalytics/FrontLinePerformance'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areabusinesslinemanager-salespersonsperformance`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/DataAnalytics/SalesPersonsPerformance'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areabusinesslinemanager-salespersonflcombined`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/DataAnalytics/SalesPersonFLCombined'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areabusinesslinemanager-analytics/settings`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/DataAnalytics/Settings'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* VA Paths */}
        <Route path={`${match.url}va/areabusinesslinemanager-usersummary`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/UserSummary'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-individualsummary`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/UserSummary/IndividualSummary'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-kpisummary`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/UserSummary/KpiSummary'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-mycontracts`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/MyContracts'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-forecast`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Forecast'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/MyContracts'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-mycontract-edit/:id`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/MyContracts/Edit/index'))} />
        <Route path={`${match.url}va/areabusinesslinemanagerdashboard`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Dashboard'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-listtarget`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/TargetSettings/ListTarget'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-addtarget`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/TargetSettings/AddTarget'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-edittarget/:id`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/TargetSettings/EditTarget'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-changecommission`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Exceptions/ChangeCommission'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-changecommissionapprove/:year/:quarter/:geographicallevel/:id/:businessline/:projecttype/:yeartext/:subprojecttype`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Exceptions/ChangeCommissionApprove'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-collectioncommission`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Exceptions/CollectionCommission/CollectionCommission'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-collectioncommissionapprove/:year/:quarter/:geographicallevel/:id/:businessline/:projecttype/:yeartext/:roleid`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Exceptions/CollectionCommission/ChangeCollectionCommissionApprove'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-commissiondetailedreport`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-commissionsummaryreport`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Report/CommissionSummaryReport'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-targetdistributorreport`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Report/TargetDistributorReport'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-payoutstatusreport`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Report/PayoutStatusReport'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-overachievementexception`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Approvals/OverAchievementException'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-kmta`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/UserSummary/KMTA/index.js'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-overachievementexceptiondetails`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/VA/Approvals/OverAchievementException/OverAchievementExceptionDetails');}
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}va/areabusinesslinemanager-viewtarget/:id`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/TargetSettings/ViewTarget'))} />

          <Route path={`${match.url}va/areabusinesslinemanager-overachievementexceptionhistory`} component={asyncComponent(() => import('./AreaBusinesslineManager/VA/Approvals/OverAchievementException/OverAchievementExceptionHistory'))} />
        <Route path={`${match.url}va/areabusinesslinemanager-overachievementexceptionhistorydetails/:id`} component={asyncComponent(() => {
          if (["6"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaBusinesslineManager/VA/Approvals/OverAchievementException/OverAchievementExceptionHistoryDetails');}
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
      </Switch>
      <Switch>
        {/* <Route path={`${match.url}areapayrollmanagerlanding`} component={asyncComponent(() => import('./AreaPayrollManager/APMLanding'))}/> */}
        <Route path={`${match.url}areapayrollmanagerlanding`} component={asyncComponent(() => {
          if (["5"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaPayrollManager/APMLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areapayrollmanagerdashboard`} component={asyncComponent(() => import('./AreaPayrollManager/Dashboard'))}/> */}
        <Route path={`${match.url}areapayrollmanagerdashboard`} component={asyncComponent(() => {
          if (["5"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaPayrollManager/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areapayrollmanager-pendingpayouts`} component={asyncComponent(() => import('./AreaPayrollManager/Payouts/PendingPayouts'))}/> */}
        <Route path={`${match.url}areapayrollmanager-pendingpayouts`} component={asyncComponent(() => {
          if (["5"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaPayrollManager/Payouts/PendingPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areapayrollmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./AreaPayrollManager/Payouts/PendingPayoutDetails'))}/> */}
        <Route path={`${match.url}areapayrollmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => {
          if (["5"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaPayrollManager/Payouts/PendingPayoutDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areapayrollmanager-approvedpayouts`} component={asyncComponent(() => import('./AreaPayrollManager/Payouts/ApprovedPayouts'))}/> */}
        <Route path={`${match.url}areapayrollmanager-approvedpayouts`} component={asyncComponent(() => {
          if (["5"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaPayrollManager/Payouts/ApprovedPayouts'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areapayrollmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./AreaPayrollManager/Payouts/CheckPayoutStatusDetails'))}/>       */}
        <Route path={`${match.url}areapayrollmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => {
          if (["5"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaPayrollManager/Payouts/CheckPayoutStatusDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areapayrollmanager-sales`} component={asyncComponent(() => import('./AreaPayrollManager/Sales'))}/> */}
        <Route path={`${match.url}areapayrollmanager-sales`} component={asyncComponent(() => {
          if (["5"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaPayrollManager/Sales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* VA Paths */}
        <Route path={`${match.url}va/areapayrollmanagerdashboard`} component={asyncComponent(() => import('./AreaPayrollManager/VA/Dashboard'))} />
        <Route path={`${match.url}va/areapayrollmanager-approvedpayouts`} component={asyncComponent(() => import('./AreaPayrollManager/VA/Payouts/ApprovedPayouts'))} />
        <Route path={`${match.url}va/areapayrollmanager-pendingpayout/:id/:quarter`} component={asyncComponent(() => import('./AreaPayrollManager/VA/Payouts/PendingPayoutDetails'))} />
        <Route path={`${match.url}va/areapayrollmanager-checkstatus/:id/:quarter/`} component={asyncComponent(() => import('./AreaPayrollManager/VA/Payouts/CheckPayoutStatusDetails'))} />
        <Route path={`${match.url}va/areapayrollmanager-pendingpayouts`} component={asyncComponent(() => import('./AreaPayrollManager/VA/Payouts/PendingPayouts'))} />
        <Route path={`${match.url}va/areapayrollmanager-commissiondetailedreport`} component={asyncComponent(() => import('./AreaPayrollManager/VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/areapayrollmanager-commissionsummaryreport`} component={asyncComponent(() => import('./AreaPayrollManager/VA/Report/CommissionSummaryReport'))} />
      </Switch>
      <Switch>
        {/* <Route path={`${match.url}areacbmlanding`} component={asyncComponent(() => import('./AreaCBM/ACBMLanding'))}/> */}
        <Route path={`${match.url}areacbmlanding`} component={asyncComponent(() => {
          if (["4"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaCBM/ACBMLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areacbmdashboard`} component={asyncComponent(() => import('./AreaCBM/Dashboard'))}/> */}
        <Route path={`${match.url}areacbmdashboard`} component={asyncComponent(() => {
          if (["4"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaCBM/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areacbm-sales`} component={asyncComponent(() => import('./AreaCBM/Sales'))}/> */}
        <Route path={`${match.url}areacbm-sales`} component={asyncComponent(() => {
          if (["4"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaCBM/Sales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areacbm-changecommissionreport`} component={asyncComponent(() => import('./AreaCBM/Report/ChangeCommissionReport'))}/> */}
        <Route path={`${match.url}areacbm-changecommissionreport`} component={asyncComponent(() => {
          if (["4"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaCBM/Report/ChangeCommissionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areacbm-correctionreport`} component={asyncComponent(() => import('./AreaCBM/Report/CorrectionReport'))}/> */}
        <Route path={`${match.url}areacbm-correctionreport`} component={asyncComponent(() => {
          if (["4"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaCBM/Report/CorrectionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areacbm-payoutstatusreport`} component={asyncComponent(() => import('./AreaCBM/Report/PayoutStatusReport'))}/> */}
        <Route path={`${match.url}areacbm-payoutstatusreport`} component={asyncComponent(() => {
          if (["4"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaCBM/Report/PayoutStatusReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areacbm-targetdistributorreport`} component={asyncComponent(() => import('./AreaCBM/Report/TargetDistributorReport'))}/> */}
        <Route path={`${match.url}areacbm-targetdistributorreport`} component={asyncComponent(() => {
          if (["4"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaCBM/Report/TargetDistributorReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* VA Paths */}
        <Route path={`${match.url}va/areacbmdashboard`} component={asyncComponent(() => import('./AreaCBM/VA/Dashboard'))} />
        <Route path={`${match.url}va/areacbm-targetdistributorreport`} component={asyncComponent(() => import('./AreaCBM/VA/Report/TargetDistributorReport'))} />
        <Route path={`${match.url}va/areacbm-payoutstatusreport`} component={asyncComponent(() => import('./AreaCBM/VA/Report/PayoutStatusReport'))} />
        <Route path={`${match.url}va/areacbm-payoutreport`} component={asyncComponent(() => import('./AreaCBM/VA/Report/PayoutReport'))} />
      </Switch>
      <Switch>
        {/* <Route path={`${match.url}areahrdirectorlanding`} component={asyncComponent(() => import('./AreaHRDirector/AHRDLanding'))}/> */}
        <Route path={`${match.url}areahrdirectorlanding`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/AHRDLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirectordashboard`} component={asyncComponent(() => import('./AreaHRDirector/Dashboard'))}/> */}
        <Route path={`${match.url}areahrdirectordashboard`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirector-changecommissionlist`} component={asyncComponent(() => import('./AreaHRDirector/PendingApprovals/ChangeCommissionList'))}/> */}
        <Route path={`${match.url}areahrdirector-changecommissionlist`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/PendingApprovals/ChangeCommissionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
         <Route path={`${match.url}areahrdirector-sales`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/Sales/'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirector-editcommissionpending/:id`} component={asyncComponent(() => import('./AreaHRDirector/PendingApprovals/ChangeCommissionList/EditCommissionPending'))}/>       */}
        <Route path={`${match.url}areahrdirector-editcommissionpending/:id`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/PendingApprovals/ChangeCommissionList/EditCommissionPending'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirector-commissionlisthistory`} component={asyncComponent(() => import('./AreaHRDirector/PendingApprovals/CommissionListHistory'))}/> */}
        <Route path={`${match.url}areahrdirector-commissionlisthistory`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/PendingApprovals/CommissionListHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        <Route path={`${match.url}areahrdirector-awaitingcommisssionexceptions`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/PendingApprovals/AwaitingCommissionExceptionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areahrdirector-editawaitingcommissionpending/:id`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/PendingApprovals/AwaitingCommissionExceptionList/EditCommissionPending'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* <Route path={`${match.url}areahrdirector-viewcommissionhistorydetails/:id`} component={asyncComponent(() => import('./AreaHRDirector/PendingApprovals/CommissionListHistory/CommissionHistoryDetails'))}/>                               */}
        <Route path={`${match.url}areahrdirector-viewcommissionhistorydetails/:id`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/PendingApprovals/CommissionListHistory/CommissionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirector-rejectedcommissionlist`} component={asyncComponent(() => import('./AreaHRDirector/Rejections/RejectedCommissionList'))}/> */}
        <Route path={`${match.url}areahrdirector-rejectedcommissionlist`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/Rejections/RejectedCommissionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirector-viewcommissionrejected/:id`} component={asyncComponent(() => import('./AreaHRDirector/Rejections/RejectedCommissionList/EditCommissionRejected'))}/> */}
        <Route path={`${match.url}areahrdirector-viewcommissionrejected/:id`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/Rejections/RejectedCommissionList/EditCommissionRejected'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirector-changecommissionreport`} component={asyncComponent(() => import('./AreaHRDirector/Report/ChangeCommissionReport'))}/> */}
        <Route path={`${match.url}areahrdirector-changecommissionreport`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/Report/ChangeCommissionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirector-correctionreport`} component={asyncComponent(() => import('./AreaHRDirector/Report/CorrectionReport'))}/> */}
        <Route path={`${match.url}areahrdirector-correctionreport`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/Report/CorrectionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirector-payoutstatusreport`} component={asyncComponent(() => import('./AreaHRDirector/Report/PayoutStatusReport'))}/> */}
        <Route path={`${match.url}areahrdirector-payoutstatusreport`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/Report/PayoutStatusReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areahrdirector-targetdistributorreport`} component={asyncComponent(() => import('./AreaHRDirector/Report/TargetDistributorReport'))}/> */}
        <Route path={`${match.url}areahrdirector-targetdistributorreport`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/Report/TargetDistributorReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areahrdirector-dataanalytics`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/DataAnalytics/MenuOptionsPage'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* Analytics Routes */}
        <Route path={`${match.url}areahrdirector-frontlineperformance`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/DataAnalytics/FrontLinePerformance'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areahrdirector-salespersonsperformance`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/DataAnalytics/SalesPersonsPerformance'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areahrdirector-salespersonflcombined`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/DataAnalytics/SalesPersonFLCombined'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        <Route path={`${match.url}areahrdirector-analytics/settings`} component={asyncComponent(() => {
          if (["3"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaHRDirector/DataAnalytics/Settings'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />


        {/* VA Paths */}
        <Route path={`${match.url}va/areahrdirectordashboard`} component={asyncComponent(() => import('./AreaHRDirector/VA/Dashboard'))} />
        <Route path={`${match.url}va/areahrdirector-changecommission`} component={asyncComponent(() => import('./AreaHRDirector/VA/Exceptions/ChangeCommission'))} />
        <Route path={`${match.url}va/areahrdirector-changecommissionapprove/:year/:quarter/:geographicallevel/:id/:businessline/:projecttype/:yeartext/:subprojecttype`} component={asyncComponent(() => import('./AreaHRDirector/VA/Exceptions/ChangeCommissionApprove'))} />
        <Route path={`${match.url}va/areahrdirector-collectioncommission`} component={asyncComponent(() => import('./AreaHRDirector/VA/Exceptions/CollectionCommission/CollectionCommission'))} />
        <Route path={`${match.url}va/areahrdirector-collectioncommissionapprove/:year/:quarter/:geographicallevel/:id/:businessline/:projecttype/:yeartext/:roleid`} component={asyncComponent(() => import('./AreaHRDirector/VA/Exceptions/CollectionCommission/ChangeCollectionCommissionApprove'))} />
        <Route path={`${match.url}va/areahrdirector-commissiondetailedreport`} component={asyncComponent(() => import('./AreaHRDirector/VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/areahrdirector-commissionsummaryreport`} component={asyncComponent(() => import('./AreaHRDirector/VA/Report/CommissionSummaryReport'))} />
        <Route path={`${match.url}va/areahrdirector-targetdistributorreport`} component={asyncComponent(() => import('./AreaHRDirector/VA/Report/TargetDistributorReport'))} />
        <Route path={`${match.url}va/areahrdirector-payoutstatusreport`} component={asyncComponent(() => import('./AreaHRDirector/VA/Report/PayoutStatusReport'))} />
        <Route path={`${match.url}va/areahrdirector-payoutreport`} component={asyncComponent(() => import('./AreaHRDirector/VA/Report/PayoutReport'))} />

      </Switch>
      <Switch>
        {/* <Route path={`${match.url}areamdlanding`} component={asyncComponent(() => import('./AreaMD/AMDLanding'))}/> */}
        <Route path={`${match.url}areamdlanding`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/AMDLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamddashboard`} component={asyncComponent(() => import('./AreaMD/Dashboard'))}/> */}
        <Route path={`${match.url}areamddashboard`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/Dashboard'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-sales`} component={asyncComponent(() => import('./AreaMD/Sales'))}/> */}
        <Route path={`${match.url}areamd-sales`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/Sales'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-editcommission/:id`} component={asyncComponent(() => import('./AreaMD/Sales/EditCommission'))}/>       */}
        <Route path={`${match.url}areamd-editcommission/:id`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/Sales/EditCommission'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-changecommissionlist`} component={asyncComponent(() => import('./AreaMD/PendingApprovals/ChangeCommissionList'))}/>       */}
        <Route path={`${match.url}areamd-changecommissionlist`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/PendingApprovals/ChangeCommissionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-editcommissionpending/:id`} component={asyncComponent(() => import('./AreaMD/PendingApprovals/ChangeCommissionList/EditCommissionPending'))}/>       */}
        <Route path={`${match.url}areamd-editcommissionpending/:id`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/PendingApprovals/ChangeCommissionList/EditCommissionPending'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-commissionlisthistory`} component={asyncComponent(() => import('./AreaMD/PendingApprovals/CommissionListHistory'))}/> */}
        <Route path={`${match.url}areamd-commissionlisthistory`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/PendingApprovals/CommissionListHistory'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-viewcommissionhistorydetails/:id`} component={asyncComponent(() => import('./AreaMD/PendingApprovals/CommissionListHistory/CommissionHistoryDetails'))}/> */}
        <Route path={`${match.url}areamd-viewcommissionhistorydetails/:id`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/PendingApprovals/CommissionListHistory/CommissionHistoryDetails'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-rejectedcommissionlist`} component={asyncComponent(() => import('./AreaMD/Rejections/RejectedCommissionList'))}/> */}
        <Route path={`${match.url}areamd-rejectedcommissionlist`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/Rejections/RejectedCommissionList'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-viewcommissionrejected/:id`} component={asyncComponent(() => import('./AreaMD/Rejections/RejectedCommissionList/EditCommissionRejected'))}/> */}
        <Route path={`${match.url}areamd-viewcommissionrejected/:id`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/Rejections/RejectedCommissionList/EditCommissionRejected'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-listtarget`} component={asyncComponent(() => import('./AreaMD/TargetSettings/ListTarget'))}/> */}
        <Route path={`${match.url}areamd-listtarget`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/TargetSettings/ListTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-addtarget`} component={asyncComponent(() => import('./AreaMD/TargetSettings/AddTarget'))}/> */}
        <Route path={`${match.url}areamd-addtarget`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/TargetSettings/AddTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-edittarget/:id`} component={asyncComponent(() => import('./AreaMD/TargetSettings/EditTarget'))}/>       */}
        < Route path={`${match.url}areamd-edittarget/:id`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/TargetSettings/EditTarget'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-branchsimulator`} component={asyncComponent(() => import('./AreaMD/BranchSimulatorAMD'))}/> */}
        <Route path={`${match.url}areamd-branchsimulator`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/BranchSimulatorAMD'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-incentivesimulator`} component={asyncComponent(() => import('./AreaMD/IncentiveSimulatorAMD'))}/> */}
        <Route path={`${match.url}areamd-incentivesimulator`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/IncentiveSimulatorAMD'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-changecommissionreport`} component={asyncComponent(() => import('./AreaMD/Report/ChangeCommissionReport'))}/> */}
        <Route path={`${match.url}areamd-changecommissionreport`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/Report/ChangeCommissionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-correctionreport`} component={asyncComponent(() => import('./AreaMD/Report/CorrectionReport'))}/> */}
        <Route path={`${match.url}areamd-correctionreport`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/Report/CorrectionReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-payoutstatusreport`} component={asyncComponent(() => import('./AreaMD/Report/PayoutStatusReport'))}/> */}
        <Route path={`${match.url}areamd-payoutstatusreport`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/Report/PayoutStatusReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* <Route path={`${match.url}areamd-targetdistributorreport`} component={asyncComponent(() => import('./AreaMD/Report/TargetDistributorReport'))}/> */}
        <Route path={`${match.url}areamd-targetdistributorreport`} component={asyncComponent(() => {
          if (["2"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./AreaMD/Report/TargetDistributorReport'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        {/* VA Paths */}
        <Route path={`${match.url}va/areamddashboard`} component={asyncComponent(() => import('./AreaMD/VA/Dashboard'))} />
        <Route path={`${match.url}va/areamd-listtarget`} component={asyncComponent(() => import('./AreaMD/VA/TargetSettings/ListTarget'))} />
        <Route path={`${match.url}va/areamd-addtarget`} component={asyncComponent(() => import('./AreaMD/VA/TargetSettings/AddTarget'))} />
        <Route path={`${match.url}va/areamd-edittarget/:id`} component={asyncComponent(() => import('./AreaMD/VA/TargetSettings/EditTarget'))} />
        {/* <Route path={`${match.url}va/areamd-viewtarget/:id`} component={asyncComponent(() => import('./AreaMD/VA/TargetSettings/ListTarget/TargetSettingsView'))} /> */}
        <Route path={`${match.url}va/areamd-changecommission`} component={asyncComponent(() => import('./AreaMD/VA/Exceptions/ChangeCommission'))} />
        <Route path={`${match.url}va/areamd-changecommissionapprove/:year/:quarter/:geographicallevel/:id/:businessline/:projecttype/:yeartext/:subprojecttype`} component={asyncComponent(() => import('./AreaMD/VA/Exceptions/ChangeCommissionApprove'))} />
        <Route path={`${match.url}va/areamd-collectioncommission`} component={asyncComponent(() => import('./AreaMD/VA/Exceptions/CollectionCommission/CollectionCommission'))} />
        <Route path={`${match.url}va/areamd-collectioncommissionapprove/:year/:quarter/:geographicallevel/:id/:businessline/:projecttype/:yeartext/:roleid`} component={asyncComponent(() => import('./AreaMD/VA/Exceptions/CollectionCommission/ChangeCollectionCommissionApprove'))} />
        <Route path={`${match.url}va/areamd-commissiondetailedreport`} component={asyncComponent(() => import('./AreaMD/VA/Report/CommissionDetailedReport'))} />
        <Route path={`${match.url}va/areamd-commissionsummaryreport`} component={asyncComponent(() => import('./AreaMD/VA/Report/CommissionSummaryReport'))} />
        <Route path={`${match.url}va/areamd-targetdistributorreport`} component={asyncComponent(() => import('./AreaMD/VA/Report/TargetDistributorReport'))} />
        <Route path={`${match.url}va/areamd-payoutstatusreport`} component={asyncComponent(() => import('./AreaMD/VA/Report/PayoutStatusReport'))} />
        <Route path={`${match.url}va/areamd-payoutreport`} component={asyncComponent(() => import('./AreaMD/VA/Report/PayoutReport'))} />
        <Route path={`${match.url}va/areamd-viewtarget/:id`} component={asyncComponent(() => import('./AreaMD/VA/TargetSettings/ViewTarget'))} />

      </Switch>

      {/* specialist  path */}

      <Switch>
        {/* <Route path={`${match.url}salesmanlanding`} component={asyncComponent(() => import('./Salesman/SalesmanLanding'))}/> */}
        <Route path={`${match.url}specialistlanding`} component={asyncComponent(() => {
          if (["14"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Specialist/SpecialistLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* VA Paths */}
        <Route path={`${match.url}va/specialistdashboard`} component={asyncComponent(() => import('./Specialist/VA/Dashboard'))} />
        <Route path={`${match.url}va/specialist-incentivesimulator`} component={asyncComponent(() => import('./Specialist/VA/IncentiveSimulatorSMANVA'))} />
        <Route path={`${match.url}va/specialist-mycontracts`} component={asyncComponent(() => import('./Specialist/VA/MyContracts'))} />
        <Route path={`${match.url}va/specialist-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./Specialist/VA/MyContracts'))} />
        <Route path={`${match.url}va/specialist-usersummary`} component={asyncComponent(() => import('./Specialist/VA/UserSummary/IndividualSummary')) }/>

       <Route path={`${match.url}va/specialist-awaitinglimboapprovals/:id`} component={asyncComponent(() => import('./Specialist/VA/LimboSMVA/AwaitingLimbo/LimboAwaitingDetails'))} />
       <Route path={`${match.url}va/specialist-limbo`} component={asyncComponent(() => import('./Specialist/VA/LimboSMVA'))} />
       <Route path={`${match.url}va/specialist-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./Specialist/VA/LimboSMVA/LimboDetails'))} />
       <Route path={`${match.url}va/specialist-approvedlimboapprovals/:id`} component={asyncComponent(() => import('./Specialist/VA/LimboSMVA/ApprovedLimbo/LimboApprovalDetails'))} />
       <Route path={`${match.url}va/specialist-approvedlimboapprovals`} component={asyncComponent(() => import('./Specialist/VA/LimboSMVA/ApprovedLimbo'))} />
       <Route path={`${match.url}va/specialist-awaitinglimboapprovals`} component={asyncComponent(() => import('./Specialist/VA/LimboSMVA/AwaitingLimbo'))} />
      </Switch>


      {/* hunter  path */}

      <Switch>
        {/* <Route path={`${match.url}salesmanlanding`} component={asyncComponent(() => import('./Salesman/SalesmanLanding'))}/> */}
        <Route path={`${match.url}hunterlanding`} component={asyncComponent(() => {
          if (["15"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Hunter/HunterLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* VA Paths */}
        <Route path={`${match.url}va/hunterdashboard`} component={asyncComponent(() => import('./Hunter/VA/Dashboard'))} />
        <Route path={`${match.url}vahunter/hunter/:id/:businessline/:businessline_name/:year/:geographicallevel`} component={asyncComponent(() => import('./VASalesPerformance/SalesManDetails'))} />
        <Route path={`${match.url}va/hunter-incentivesimulator`} component={asyncComponent(() => import('./Hunter/VA/IncentiveSimulatorHunterVA'))} />
        <Route path={`${match.url}va/hunter-usersummary`} component={asyncComponent(() => import('./Hunter/VA/UserSummary/IndividualSummary')) }/>
        <Route path={`${match.url}va/hunter-mycontracts`} component={asyncComponent(() => import('./Hunter/VA/MyContracts')) }/>

       <Route path={`${match.url}va/hunter-awaitinglimboapprovals/:id`} component={asyncComponent(() => import('./Hunter/VA/LimboSMVA/AwaitingLimbo/LimboAwaitingDetails'))} />
       <Route path={`${match.url}va/hunter-limbo`} component={asyncComponent(() => import('./Hunter/VA/LimboSMVA'))} />
       <Route path={`${match.url}va/hunter-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./Hunter/VA/LimboSMVA/LimboDetails'))} />
       <Route path={`${match.url}va/hunter-approvedlimboapprovals/:id`} component={asyncComponent(() => import('./Hunter/VA/LimboSMVA/ApprovedLimbo/LimboApprovalDetails'))} />
       <Route path={`${match.url}va/hunter-approvedlimboapprovals`} component={asyncComponent(() => import('./Hunter/VA/LimboSMVA/ApprovedLimbo'))} />
       <Route path={`${match.url}va/hunter-awaitinglimboapprovals`} component={asyncComponent(() => import('./Hunter/VA/LimboSMVA/AwaitingLimbo'))} />
      </Switch>

      {/* Farmer path */}

      <Switch>
        {/* <Route path={`${match.url}salesmanlanding`} component={asyncComponent(() => import('./Salesman/SalesmanLanding'))}/> */}
        <Route path={`${match.url}farmerlanding`} component={asyncComponent(() => {
          if (["20"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./Farmer/FarmerLanding'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />
        
        {/* VA Paths */}
        <Route path={`${match.url}va/farmerdashboard`} component={asyncComponent(() => import('./Farmer/VA/Dashboard'))} />
        <Route path={`${match.url}vasalesperson/salesman/:id/:businessline/:businessline_name/:year/:geographicallevel`} component={asyncComponent(() => import('./VASalesPerformance/SalesManDetails'))} />
        <Route path={`${match.url}va/farmer-incentivesimulator`} component={asyncComponent(() => import('./Farmer/VA/IncentiveSimulatorSMANVA'))} />
        <Route path={`${match.url}va/farmer-mycontracts`} component={asyncComponent(() => import('./Farmer/VA/MyContracts'))} />
        <Route path={`${match.url}va/farmer-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./Farmer/VA/MyContracts'))} />
        <Route path={`${match.url}va/farmer-usersummary`} component={asyncComponent(() => import('./Farmer/VA/UserSummary/IndividualSummary')) }/>

       <Route path={`${match.url}va/farmer-awaitinglimboapprovals/:id`} component={asyncComponent(() => import('./Farmer/VA/LimboSMVA/AwaitingLimbo/LimboAwaitingDetails'))} />
       <Route path={`${match.url}va/farmer-limbo`} component={asyncComponent(() => import('./Farmer/VA/LimboSMVA'))} />
       <Route path={`${match.url}va/farmer-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./Farmer/VA/LimboSMVA/LimboDetails'))} />
       <Route path={`${match.url}va/farmer-approvedlimboapprovals/:id`} component={asyncComponent(() => import('./Farmer/VA/LimboSMVA/ApprovedLimbo/LimboApprovalDetails'))} />
       <Route path={`${match.url}va/farmer-approvedlimboapprovals`} component={asyncComponent(() => import('./Farmer/VA/LimboSMVA/ApprovedLimbo'))} />
       <Route path={`${match.url}va/farmer-awaitinglimboapprovals`} component={asyncComponent(() => import('./Farmer/VA/LimboSMVA/AwaitingLimbo'))} />
        {/* <Route path={`${match.url}vaSalesperformance/farmer/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/SalesManDetails'))} /> */}
        
      </Switch>


      {/* KAMManager path */}
      <Switch>
        <Route path={`${match.url}kammanagerlanding`} component={asyncComponent(() => {
          if (["19"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./KAMManager/KAMManagerLanding/index.js'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })}
        />

        {/* VA Paths */}
        <Route path={`${match.url}va/kammanagerdashboard`} component={asyncComponent(() => import('./KAMManager/VA/Dashboard'))} />
        <Route path={`${match.url}va/kammanager-incentivesimulator`} component={asyncComponent(() => import('./KAMManager/VA/IncentiveSimulatorHunterVA'))} />
        {/* <Route path={`${match.url}vaSalesperformance/keyaccountmanager/:id/:businessline/:businessline_name/:geographicallevel/:year`} component={asyncComponent(() => import('./VASalesPerformance/SalesManDetails'))} /> */}
        <Route path={`${match.url}va/kammanager-usersummary`} component={asyncComponent(() => import('./KAMManager/VA/UserSummary/IndividualSummary')) }/>
        <Route path={`${match.url}va/kammanager-mycontracts`} component={asyncComponent(() => import('./KAMManager/VA/MyContracts'))} />

       <Route path={`${match.url}va/kammanager-awaitinglimboapprovals/:id`} component={asyncComponent(() => import('./KAMManager/VA/LimboSMVA/AwaitingLimbo/LimboAwaitingDetails'))} />
       <Route path={`${match.url}va/kammanager-limbo`} component={asyncComponent(() => import('./KAMManager/VA/LimboSMVA'))} />
       <Route path={`${match.url}va/kammanager-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./KAMManager/VA/LimboSMVA/LimboDetails'))} />
       <Route path={`${match.url}va/kammanager-approvedlimboapprovals/:id`} component={asyncComponent(() => import('./KAMManager/VA/LimboSMVA/ApprovedLimbo/LimboApprovalDetails'))} />
       <Route path={`${match.url}va/kammanager-approvedlimboapprovals`} component={asyncComponent(() => import('./KAMManager/VA/LimboSMVA/ApprovedLimbo'))} />
       <Route path={`${match.url}va/kammanager-awaitinglimboapprovals`} component={asyncComponent(() => import('./KAMManager/VA/LimboSMVA/AwaitingLimbo'))} />
      </Switch>

      {/* KBS path */}

      <Switch>
        {/* <Route path={`${match.url}salesmanlanding`} component={asyncComponent(() => import('./Salesman/SalesmanLanding'))}/> */}
        <Route path={`${match.url}kbsuserlanding`} component={asyncComponent(() => {
          if (["16"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./KBSUser/KbsLanding/index'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        
        {/* VA Paths */}
        <Route path={`${match.url}va/kbsuser-usersummary`} component={asyncComponent(() => import('./KBSUser/VA/UserSummary'))} />
        <Route path={`${match.url}va/kbsuser-individualsummary`} component={asyncComponent(() => import('./KBSUser/VA/UserSummary/IndividualSummary'))} />
        <Route path={`${match.url}va/kbsuser-kpisummary`} component={asyncComponent(() => import('./KBSUser/VA/UserSummary/KpiSummary'))} />
        <Route path={`${match.url}va/kbsuser-mycontracts`} component={asyncComponent(() => import('./KBSUser/VA/MyContracts'))} />
        <Route path={`${match.url}va/kbsuser-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./KBSUser/VA/MyContracts'))} />
        <Route path={`${match.url}va/kbsuser-mycontract-edit/:id`} component={asyncComponent(() => import('./KBSUser/VA/MyContracts/Edit/index'))} />
        <Route path={`${match.url}va/kbsuser-contractupload`} component={asyncComponent(() => import('./KBSUser/VA/ContractUpload'))} />
        <Route path={`${match.url}va/kbsuser-kmta`} component={asyncComponent(() => import('./KBSUser/VA/UserSummary/KMTA/index.js'))} />      
        {/* Limbo */}
        <Route path={`${match.url}va/kbsuser-limbo-details/:project_type/:id/:tablename/:columnname/:kmta`} component={asyncComponent(() => import('./KBSUser/VA/LimboSMVA/LimboSummary/LimboDetails'))} />
        <Route path={`${match.url}va/kbsuser-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./KBSUser/VA/LimboSMVA/LimboSummary/LimboDetails'))} />
        <Route path={`${match.url}va/kbsuser-limboapprovals/:id`} component={asyncComponent(() => import('./KBSUser/VA/LimboSMVA/LimboSummary/LimboApprovalDetails/index.js'))} />
        <Route path={`${match.url}va/kbsuser-limbo`} component={asyncComponent(() => import('./KBSUser/VA/LimboSMVA/LimboSummary'))} /> 
        <Route path={`${match.url}va/kbsuser-limbo-expired_180_days`} component={asyncComponent(() => import('./KBSUser/VA/LimboSMVA/Expired_180/index.js'))} />
        <Route path={`${match.url}va/kbsuser-limbo-expired_1_to_30_days`} component={asyncComponent(() => import('./KBSUser/VA/LimboSMVA/Expired_1_to_30_days'))} />
        <Route path={`${match.url}va/kbsuser-limbo-expired_31_to_90_days`} component={asyncComponent(() => import('./KBSUser/VA/LimboSMVA/Expired_31_to_90_days'))} />
        <Route path={`${match.url}va/kbsuser-limbo-expired_91_to_180_days`} component={asyncComponent(() => import('./KBSUser/VA/LimboSMVA/Expired_91_to_180_days'))} />
        <Route path={`${match.url}va/kbsuser-limbo-expiring_0_to_30_days`} component={asyncComponent(() => import('./KBSUser/VA/LimboSMVA/Expiring_0_to_30_days'))} />
       
       </Switch>

         {/*Regional Business controller: path */}

      <Switch>
        {/* <Route path={`${match.url}salesmanlanding`} component={asyncComponent(() => import('./Salesman/SalesmanLanding'))}/> */}
        <Route path={`${match.url}regionalbusinesscontrollerlanding`} component={asyncComponent(() => {
          if (["18"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./RegionalBusinessController/RegionalBusinessControllerLanding/index'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* VA Paths */}

        <Route path={`${match.url}va/regionalbusinesscontroller-usersummary`} component={asyncComponent(() => import('./RegionalBusinessController/VA/UserSummary/index'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-individualsummary`} component={asyncComponent(() => import('./RegionalBusinessController/VA/UserSummary/IndividualSummary/index'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-kpisummary`} component={asyncComponent(() => import('./RegionalBusinessController/VA/UserSummary/KpiSummary/index'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-mycontracts`} component={asyncComponent(() => import('./RegionalBusinessController/VA/MyContracts'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./RegionalBusinessController/VA/MyContracts'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-mycontract-edit/:id`} component={asyncComponent(() => import('./RegionalBusinessController/VA/MyContracts/Edit/index'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-kmta`} component={asyncComponent(() => import('./RegionalBusinessController/VA/UserSummary/KMTA/index.js'))} />
        {/* Limbo */}
        <Route path={`${match.url}va/regionalbusinesscontroller-limbo-details/:project_type/:id/:tablename/:columnname/:kmta`} component={asyncComponent(() => import('./RegionalBusinessController/VA/LimboSMVA/LimboDetails'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./RegionalBusinessController/VA/LimboSMVA/LimboDetails'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-limboapprovals/:id`} component={asyncComponent(() => import('./RegionalBusinessController/VA/LimboSMVA/LimboApprovalDetails/index.js'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-limbo`} component={asyncComponent(() => import('./RegionalBusinessController/VA/LimboSMVA'))} />
        {/* profitability */}
        <Route path={`${match.url}va/regionalbusinesscontroller-profitability-details`} component={asyncComponent(() => import('./RegionalBusinessController/VA/Profitability/ProfitabilityDetails'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-profitability`} component={asyncComponent(() => import('./RegionalBusinessController/VA/Profitability/index.js'))} />  
        {/* forecast */}
        <Route path={`${match.url}va/regionalbusinesscontroller-forecast`} component={asyncComponent(() => import('./RegionalBusinessController/VA/Forecast'))} />
         {/* billingblock */}
        <Route path={`${match.url}va/regionalbusinesscontroller-billingblock`} component={asyncComponent(() => import('./RegionalBusinessController/VA/BillingBlockSMVA'))} /> 
        <Route path={`${match.url}va/regionalbusinesscontroller-billingblock-details/:id/:tablename/:columnname/:kmta`} component={asyncComponent(() => import('./RegionalBusinessController/VA/BillingBlockSMVA/BillingBlockDetails'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-billingblock-details/:id/:tablename/:columnname`} component={asyncComponent(() => import('./RegionalBusinessController/VA/BillingBlockSMVA/BillingBlockDetails'))} />
        {/* mso */}
        <Route path={`${match.url}va/regionalbusinesscontroller-mso`} component={asyncComponent(() => import('./RegionalBusinessController/VA/MSOSMVA'))} /> 
        <Route path={`${match.url}va/regionalbusinesscontroller-mso-details/:id/:tablename/:columnname/:kmta`} component={asyncComponent(() => import('./RegionalBusinessController/VA/MSOSMVA/MSODetails'))} />
        <Route path={`${match.url}va/regionalbusinesscontroller-mso-details/:id/:tablename/:columnname`} component={asyncComponent(() => import('./RegionalBusinessController/VA/MSOSMVA/MSODetails'))} />
       </Switch>

      {/*Business controllers path */}

      <Switch>
        {/* <Route path={`${match.url}salesmanlanding`} component={asyncComponent(() => import('./Salesman/SalesmanLanding'))}/> */}
        <Route path={`${match.url}businesscontrollerlanding`} component={asyncComponent(() => {
          if (["17"].indexOf(window.localStorage.getItem("currentRole")) !== -1) { return import('./BusinessController/BusinessControllerLanding/index'); }
          else { return () => <Redirect to={`${match.url}${window.localStorage.getItem("currentPath")}`} />; }
        })} />

        {/* VA Paths */}

        <Route path={`${match.url}va/businessusercontrollers-usersummary`} component={asyncComponent(() => import('./BusinessController/VA/UserSummary/index'))} />
        <Route path={`${match.url}va/businessusercontrollers-individualsummary`} component={asyncComponent(() => import('./BusinessController/VA/UserSummary/IndividualSummary/index'))} />
        <Route path={`${match.url}va/businessusercontrollers-kpisummary`} component={asyncComponent(() => import('./BusinessController/VA/UserSummary/KpiSummary/index'))} />
        <Route path={`${match.url}va/businessusercontrollers-mycontracts`} component={asyncComponent(() => import('./BusinessController/VA/MyContracts'))} />
        <Route path={`${match.url}va/businessusercontrollers-mycontracts/:column/:monthid/:year`} component={asyncComponent(() => import('./BusinessController/VA/MyContracts'))} />
        <Route path={`${match.url}va/businessusercontrollers-mycontract-edit/:id`} component={asyncComponent(() => import('./BusinessController/VA/MyContracts/Edit/index'))} />
        <Route path={`${match.url}va/businessusercontrollers-limbo-details/:project_type/:id/:tablename/:columnname/:kmta`} component={asyncComponent(() => import('./BusinessController/VA/LimboSMVA/LimboDetails'))} />
        <Route path={`${match.url}va/businessusercontrollers-limbo-details/:project_type/:id/:tablename/:columnname`} component={asyncComponent(() => import('./BusinessController/VA/LimboSMVA/LimboDetails'))} />
        <Route path={`${match.url}va/businessusercontrollers-limbo`} component={asyncComponent(() => import('./BusinessController/VA/LimboSMVA'))} />
       </Switch>
    </div>
  )
};

export default App;
